import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { Store } from 'redux'
import { History } from 'history'
import './helpers/i18n'
import { ApplicationState } from './stores'
import Home from './screens/Home'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import BlogDetail from './screens/BlogDetail'
import FleetManagement from './screens/FleetManagement'
import Technology from './screens/Technology'
import NotFound from './screens/404'
import Industry from './screens/Industry'
import BlogContent from './screens/BlogContent'
import Solution from './screens/Solution'
import Blog from './screens/Blog'
import Career from './screens/Career'
import Privacy from './screens/Privacy'

interface IAppProps {
  store: Store<ApplicationState>
  history: History
}

const App: React.FC<IAppProps> = ({ store, history }) => {
  const { i18n } = useTranslation()

  useEffect(() => {
    const initiate = async () => {
      const defaultLng = localStorage.getItem('@defaultLng')
      i18n.changeLanguage(defaultLng ? defaultLng : 'id')
    }

    initiate()
  }, [i18n])

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <BrowserRouter basename={process.env.REACT_APP_PUBLIC_PATH}>
          <Switch>
            <Route exact path='/' component={Home}/>
            <Route path='/details/:slug' component={BlogDetail}/>
            <Route path='/blog' component={Blog}/>
            <Route path='/resources/:slug' component={BlogContent}/>
            <Route path='/fleet-management' component={FleetManagement}/>
            <Route path='/technology' component={Technology}/>
            <Route path='/industries' component={Industry}/>
            <Route path='/solution' component={Solution}/>
            <Route path='/not-found' component={NotFound}/>
            <Route path='/career' component={Career}/>
	    <Route path='/privacy' component={Privacy}/>
            <Redirect to="/not-found" />
          </Switch>
        </BrowserRouter>
      </ConnectedRouter>
    </Provider>
  )
}

export default App
