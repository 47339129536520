import { Form, Button, InputNumber, Col, Row, message, Spin, Input } from 'antd'
import { formatterNumber, parserNumber, useWindowSize } from '../../helpers'
import { IHomeComponent } from "./types"

import FeatureBg from '../../assets/images/feature-bg.png'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { postAnalyticClick, postSavings } from '../../services/demo'
import DemoModal from './DemoModal'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const validateMessages = {
  required: '${label} is required!',
  types: {
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between Rp. 1.000.000 and Rp. 1.000.000.000',
  },
}

const Portfolio : React.FC<IHomeComponent> = ({dataSource, isMobile, showModal, ...rest }) => {
  const [width] = useWindowSize()

  const { t } = useTranslation()
  const [form] = Form.useForm();

  const [submitted, isSubmitted] = useState(false);

  const [bahanBakar, setBahanBakar] = useState<number | string | undefined>('');
  const [pemeliharaan, setPemeliharaan] = useState<number | string | undefined>('');
  const [operasional, setOperasional] = useState<number | string | undefined>('');
  const [totalBahanBakar, setTotalBahanBakar] = useState<number>(0);
  const [totalPemeliharaan, setTotalPemeliharaan] = useState<number>(0);
  const [totalOperasional, setTotalOperasional] = useState<number>(0);
  const [totalWithoutEasyGo, setTotalWithoutEasyGo] = useState<number | string | undefined>('');
  const [totalWithEasyGo, setTotalWithEasyGo] = useState<number | string | undefined>('');
  const [demoModalOpen, isDemoModalOpen] = useState<boolean>(false)
  const [loading, isLoading] = useState<boolean>(false)
  const [values, setValues] = useState<any>({})

  const onFinish = async (fieldData: any) => {
    isLoading(true)
    console.log('fieldData',fieldData)
    const data = {
      bahanBakar: fieldData.bahanBakar,
      pemeliharaan: fieldData.pemeliharaan,
      operasional: fieldData.operasional,
      email: fieldData.email,
      company: fieldData.company

    }
 
    setBahanBakar(data.bahanBakar)
    setPemeliharaan(data.pemeliharaan)
    setOperasional(data.operasional)

    const _totalBahanBakar = data.bahanBakar - (data.bahanBakar*0.2)
    const _totalPemeliharaan = data.pemeliharaan - (data.pemeliharaan*0.2)
    const _totalOperasional = data.operasional - (data.operasional*0.2)
    setTotalBahanBakar(_totalBahanBakar)
    setTotalPemeliharaan(_totalPemeliharaan)
    setTotalOperasional(_totalOperasional)

    form.setFieldsValue({
      totalBahanBakar: _totalBahanBakar,
      totalPemeliharaan: _totalPemeliharaan,
      totalOperasional: _totalOperasional,
    });
    
    setTotalWithoutEasyGo(data.bahanBakar+data.pemeliharaan+data.operasional)
    setTotalWithEasyGo(_totalBahanBakar+_totalPemeliharaan+_totalOperasional)
    
    //-- Save data
    const params = {
      fuel: data.bahanBakar,
      maintenance: data.pemeliharaan,
      operational_costs: data.operasional,
      total: data.bahanBakar+data.pemeliharaan+data.operasional,
      easygo_fuel: _totalBahanBakar,
      easygo_maintenance: _totalPemeliharaan,
      easygo_operational_costs: _totalOperasional,
      easygo_total: _totalBahanBakar+_totalPemeliharaan+_totalOperasional,
      // demo_request_id: parseInt(id_reg)
      email: data.email,
      company: data.company

    }

    const resp = await postSavings(params)
    isLoading(false)
    if(resp){
      isSubmitted(true)
    }else{
      message.error('Oops something wrong, please try again')
    }
    await postAnalyticClick('saving_clicks')
  }

  const onChange = (value: number | string | null) => {
    // console.log('changed', value);
  };
  
  const handleSubmit = async (value: any) => {
    await onFinish(value)
  }

  return (
    <div {...rest} {...dataSource.wrapper}>
      <div className="portfolio-bg">
        <img src={FeatureBg} alt="EasyGo" />
      </div>
      <div {...dataSource.page}>
        <div className="title-wrapper">
          <h2 className="title-h1">{t('saving.title')}</h2>
          {!submitted && <div>{t('saving.caption')}</div>}
        </div>

        {
          loading ? (
            <div className="loading-container">
              <Spin size="large" />
            </div>
          ) : (
            <div className='centered-pack'>
              <div className={`centered-column ${submitted && 'large-centered'}`}>
                <Form {...layout} name="easygo-messages" role="form" method="post"
                  form={form}
                  // onFinish={(valueData)=> {
                  //   console.log(valueData)
                  //   setValues(valueData)
                  //   // isDemoModalOpen(true)
                  // }}

                  onFinish={(valueData)=>handleSubmit(valueData)}
                  validateMessages={validateMessages}
                  initialValues={{
                    bahanBakar: bahanBakar,
                    pemeliharaan: pemeliharaan,
                    operasional: operasional,
                    totalBahanBakar: totalBahanBakar,
                    totalPemeliharaan: totalPemeliharaan,
                    totalOperasional: totalOperasional,
                  }}
                >
                  {submitted ? (
                    <>
                      <Row className="centered" justify="center" align="middle">
                        <Col lg={8} md={24} sm={24} xs={24}>
                          <h3 className="title-h3">{t('saving.without')}</h3>
                          <Form.Item label={width <= 834 && t('saving.fuel')} name={'bahanBakar'} rules={[{ type: 'number', min: 1000000, max: 100000000, required: true }]}>
                            <InputNumber
                              addonBefore="Rp."
                              formatter={value => formatterNumber(value)}
                              parser={value => parserNumber(value)}
                              onChange={onChange}
                              disabled
                            />
                          </Form.Item>
                          <Form.Item label={width <= 834 && t('saving.maintenance')} name={'pemeliharaan'} rules={[{ type: 'number', min: 1000000, max: 100000000, required: true }]} >
                            <InputNumber
                              addonBefore="Rp."
                              formatter={value => formatterNumber(value)}
                              parser={value => parserNumber(value)}
                              onChange={onChange}
                              width="100%"
                              disabled
                            />
                          </Form.Item>
                          <Form.Item label={width <= 834 && t('saving.operational')} name={'operasional'} rules={[{ type: 'number', min: 1000000, max: 100000000, required: true }]}>
                            <InputNumber
                              addonBefore="Rp."
                              formatter={value => formatterNumber(value)}
                              parser={value => parserNumber(value)}
                              onChange={onChange}
                              width="100%"
                              disabled
                            />
                          </Form.Item>
                          <h3 className="title-h3 center-divider">Rp. {formatterNumber(totalWithoutEasyGo)}</h3>
                        </Col>
                        {width > 834 && (
                          <Col lg={8} md={24} sm={24} xs={24}>
                            <h3 className="title-h3 center-divider">{t('saving.fuel')}</h3>
                            <h3 className="title-h3 center-divider">{t('saving.maintenance')}</h3>
                            <h3 className="title-h3 center-divider">{t('saving.operational')}</h3>
                            <h3 className="title-h3 center-divider" style={{marginTop: 25}}>{t('saving.total')}</h3>
                          </Col>
                        )}
                        <Col lg={8} md={24} sm={24} xs={24}>
                          <h3 className="title-h3">{t('saving.with')}</h3>
                          <Form.Item label={width <= 834 && t('saving.fuel')} name={'totalBahanBakar'} rules={[{ type: 'number', min: 1000000, max: 1000000000, required: true }]}>
                            <InputNumber
                              addonBefore="Rp."
                              formatter={value => formatterNumber(value)}
                              parser={value => parserNumber(value)}
                              onChange={onChange}
                              width="100%"
                              disabled
                            />
                          </Form.Item>
                          <Form.Item label={width <= 834 && t('saving.maintenance')} name={'totalPemeliharaan'} rules={[{ type: 'number', min: 1000000, max: 1000000000, required: true }]}>
                            <InputNumber
                              addonBefore="Rp."
                              formatter={value => formatterNumber(value)}
                              parser={value => parserNumber(value)}
                              onChange={onChange}
                              width="100%"
                              disabled
                            />
                          </Form.Item>
                          <Form.Item label={width <= 834 && t('saving.operational')} name={'totalOperasional'} rules={[{ type: 'number', min: 1000000, max: 1000000000, required: true }]}>
                            <InputNumber
                              addonBefore="Rp."
                              formatter={value => formatterNumber(value)}
                              parser={value => parserNumber(value)}
                              onChange={onChange}
                              width="100%"
                              disabled
                            />
                          </Form.Item>
                          
                          <h3 className="title-h3 center-divider">Rp. {formatterNumber(totalWithEasyGo)}</h3>
                        </Col>
                      </Row>
                      <Row justify="center" className="centered ant-row-center">
                        <h3 className="title-h3 bold">{t('saving.description')}</h3>
                      </Row>
                    </>
                  ) : (
                    <>
                      <Form.Item name={'bahanBakar'} label={t('saving.fuel')} rules={[{ type: 'number', min: 1000000, max: 1000000000, required: true }]}>
                        <InputNumber
                          addonBefore="Rp."
                          formatter={value => formatterNumber(value)}
                          parser={value => parserNumber(value)}
                          onChange={onChange}
                          style={{width:"100%"}}
                        />
                      </Form.Item>
                      <Form.Item name={'pemeliharaan'} label={t('saving.maintenance')} rules={[{ type: 'number', min: 1000000, max: 1000000000, required: true }]}>
                        <InputNumber
                          addonBefore="Rp."
                          formatter={value => formatterNumber(value)}
                          parser={value => parserNumber(value)}
                          onChange={onChange}
                          style={{width:"100%"}}
                        />
                      </Form.Item>
                      <Form.Item name={'operasional'} label={t('saving.operationl')} rules={[{ type: 'number', min: 1000000, max: 1000000000, required: true }]}>
                        <InputNumber
                          addonBefore="Rp."
                          formatter={value => formatterNumber(value)}
                          parser={value => parserNumber(value)}
                          onChange={onChange}
                          style={{width:"100%"}}
                        />
                      </Form.Item>

                      <Form.Item name={'email'} label={t('modal.email')} 
                        rules={
                          [
                            { type: 'email',
                              message: 'The input is not valid E-mail!',
                              required: true
                            }
                          ]
                        }
                        
                        >
                        <Input
                           placeholder="Email"
                           style={{borderRadius:20}}
                        />
                      </Form.Item>

                      <Form.Item name={'company'} label={t('modal.companyName')}
                        rules={
                          [
                            { type: 'string',
                              message: 'The input is not valid ',
                              required: true
                            }
                          ]
                        }
                        
                      >
                        <Input
                          style={{borderRadius:20}}
                        />
                      </Form.Item>


                      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                        <Button type="primary" htmlType="submit">
                          {t('saving.save')}
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form>
              </div>
            </div>
          )
        }
      </div>

      {/* <DemoModal
        modalOpen={demoModalOpen} 
        isModalOpen={isDemoModalOpen}
        onResponse={(id_reg) => onFinish(id_reg)}
      /> */}
    </div>
  )
}

export default Portfolio