import * as React from "react"
import { SVGProps } from "react"

const LinkedInIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={21}
    height={21}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.75 2.63A1.88 1.88 0 0 1 2.63.75h18.738a1.879 1.879 0 0 1 1.882 1.88v18.738a1.88 1.88 0 0 1-1.88 1.882H2.63a1.88 1.88 0 0 1-1.88-1.88V2.63Zm8.906 6.699h3.047v1.53c.44-.88 1.564-1.671 3.255-1.671 3.241 0 4.009 1.751 4.009 4.966v5.954h-3.28v-5.222c0-1.83-.44-2.863-1.556-2.863-1.55 0-2.194 1.113-2.194 2.863v5.222H9.656V9.328ZM4.03 19.969h3.28V9.186h-3.28v10.781ZM7.78 5.67a2.11 2.11 0 1 1-4.218.093 2.11 2.11 0 0 1 4.218-.093Z"
      fill="#fff"
    />
  </svg>
)

export default LinkedInIcon
