import TweenOne from 'rc-tween-one'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { IStaticComponent } from "./types"
import { Button, Card, Col, Empty, Row, Spin, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { paginateContent } from '../../services/posts'
import { useTranslation } from 'react-i18next'
import { ClientDataSource } from './data.source'
import moment from 'moment'
import ArrowRightIcon from '../Icons/ArrowRight'
import { postAnalyticClick } from '../../services/demo'

const { Text, Link } = Typography

const { Meta } = Card

const Posts: React.FC<IStaticComponent> = ({isMobile, slug, ...rest }) => {
  const { t } = useTranslation()

  const wrapper = { className: 'home-page-wrapper post-detail-wrapper' }
  const overPack = { className: 'home-page post-detail', playScale: 0.05 }

  let search = window.location.search
  let params = new URLSearchParams(search)
  let view = params.get('view')

  const [data, setData] = useState<any>()
  const [loading, isLoading] = useState<boolean>(false)
  const [articles, setArticles] = useState<any[]>([])

  useEffect(() => {
    const initiate = async () => {
      isLoading(true)
      const defaultLng = localStorage.getItem('@defaultLng')
      setData(defaultLng ? (defaultLng === 'en' ? ClientDataSource.en : ClientDataSource.id) : ClientDataSource.id)

      if (slug) {
        const language_id = defaultLng === 'en' ? 1 : 2

        switch (slug) {
          case "video":
            const videos = await paginateContent(3, language_id, 1, view === "all" ? 100 : 6)
            setArticles(videos.data)
            break;
          case "e-book":
            const ebooks = await paginateContent(4, language_id, 1, view === "all" ? 100 : 6)
            setArticles(ebooks.data)
            break;
          case "news":
            const news = await paginateContent(1, language_id, 1, view === "all" ? 100 : 6)
            setArticles(news.data)
            break;
          default:
            const posts = await paginateContent(2, language_id, 1, view === "all" ? 100 : 6)
            console.log('posts', posts)
            setArticles(posts.data)
            break;
        }
        isLoading(false)
      }
    }

    initiate()
  }, [slug, view])

  const CardCover = (article: any) => {
    if (slug === 'video') {
      const video_link = article.content.content_videos[0]?.url
      const ytCode = video_link.substring(video_link.lastIndexOf('/') + 1)
      
      return (
        <iframe
          height="200"
          src={`https://www.youtube.com/embed/${ytCode}`}
          title={article.content.content_languages[0]?.title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          className="card-iframe"
          allowFullScreen></iframe>
      )
    }
    
    return (
      <img alt={article.content.content_languages[0]?.title} src={article.content.content_images[0]?.url} />
    )
  }

  const getTitle = () => {
    switch (slug) {
      case 'video':
        return t('video')
      case 'e-book':
        return t('ebook')
      case 'news':
        return t('news')
      default:
        return t('article')
    }
  }

  const startSolutionAdvisor = async () => {
    await postAnalyticClick("advisor_clicks")
    window.location.href = "/solution"
  }

  return (
    <div {...rest} {...wrapper}>
      <OverPack {...overPack}>
        <Row>
          <Col lg={8} md={24} sm={24} xs={24} className="container">
            <div className="title-wrapper">
              <h3 className="title-h3">{t('common.content.type')}</h3>
            </div>
            <div className="columns">
              <Link href="/resources/news" className={window.location.href.indexOf("news") > -1 ? "active" : ""}>{t('news')}</Link>
              <Link href="/resources/article" className={window.location.href.indexOf("article") > -1 ? "active" : ""}>{t('article')}</Link>
              <Link href="/resources/e-book" className={window.location.href.indexOf("e-book") > -1 ? "active" : ""}>{t('ebook')}</Link>
              <Link href="/resources/video" className={window.location.href.indexOf("video") > -1 ? "active" : ""}>{t('video')}</Link>
            </div>
            <div className="center-columns">
              {data && <span dangerouslySetInnerHTML={{ __html: data.footerDescription[1] }} />}
              <Button type="primary" onClick={() => startSolutionAdvisor()}>
                {t('common.advise')}
              </Button>
            </div>
          </Col>
          <Col lg={16} md={24} sm={24} xs={24} className="container">
            <Row className="right-container">
              <Col md={12}>
                <h3 className="title-h3 bold">{getTitle()}</h3>
              </Col>
              <Col md={12}>
                {!view && (
                  <h3 className="title-h3 bold right">
                    <Link href={`/resources/${slug}?view=all`}>
                      {t('common.view.all')}
                    </Link>
                  </h3>
                )}
              </Col>
            </Row>

            {!loading ? (
              <TweenOne
                animation={{ x: '+=30', opacity: 0, type: 'from' }}
                key="post-detail"
                className="post-detail"
              >
                {articles.length > 0 ? (
                  <Row wrap>
                    {articles.map((item: any, index: number) => (
                      <div className="col-12" key={index}>
                        <Card
                          className="article-card"
                          hoverable
                          cover={CardCover(item)}
                          onClick={() => window.location.href = `/details/${item.content.slug}`}
                        >
                          <Row>
                            <Col span={12}>
                              <Text type="secondary">{moment(item.content.publish_date).format('MMM DD, YYYY')}</Text>
                            </Col>
                            {
                              slug!=='e-book' &&
                              <Col span={12} className="right">
                                <Text type="secondary">{t('common.by')} {item.content['author']['name']}</Text>
                              </Col>
                            }
                          </Row>
                          <Meta
                            title={(<Text strong >{item.content.content_languages[0]?.title}</Text>)}
                          />
                          <div className="right-button">
                            <ArrowRightIcon />
                          </div>
                        </Card>
                      </div>
                    ))}
                  </Row>
                ) : (
                  <Empty />
                )}
              </TweenOne>
            ) : (
              <div className="loading-container">
                <Spin size="large" />
              </div>
            )}
          </Col>
        </Row>
      </OverPack>
    </div>
  )
}

export default Posts