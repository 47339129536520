import { useState, useEffect } from "react"
import { enquireScreen } from 'enquire-js'
import { HeaderNavDataSource } from "../components/Home/data.source"
import PreFooter from "../components/Home/PreFooter"
import Header from "../components/Home/Header"
import Footer from "../components/Home/Footer"
import { useParams } from "react-router-dom"
import DemoModal from "../components/Home/DemoModal"
import Posts from "../components/Home/Posts"
import { postAnalyticClick } from "../services/demo"

let isThisMobile : boolean
enquireScreen((b : boolean) => {
  isThisMobile = b
})

const BlogContent = () => {
  let { slug } = useParams<any>();

  const [demoModalOpen, isDemoModalOpen] = useState(false)

  const [isMobile, setIsMobile] = useState(isThisMobile)
  const [show, isShown] = useState(false)

  useEffect(() => {
    enquireScreen((b : boolean) => {
      setIsMobile(!!b)
    })
    
    if (window.location.port) {
      setTimeout(() => {
        isShown(true)
      }, 500)
    } else {
      isShown(true)
    }
  }, [isMobile, show])

  const showDemoModal = async () => {
    isDemoModalOpen(!demoModalOpen)
    await postAnalyticClick('demo_clicks')
  }

  const children = [
    <Header
      dataSource={HeaderNavDataSource(showDemoModal)}
      isMobile={isMobile}
      key="Header"
    />,
    <Posts
      isMobile={isMobile}
      slug={slug}
      key="Posts"
    />,
    <PreFooter
      isMobile={isMobile}
      key="Pre-Footer"
    />,
    <Footer
      dataSource={[]}
      isMobile={isMobile}
      key="Footer"
    />,
  ]

  return (
    <div
      className="templates-wrapper"
    >
      {show && children}
      <DemoModal modalOpen={demoModalOpen} isModalOpen={isDemoModalOpen} />
    </div>
  )
}

export default BlogContent