import TweenOne from 'rc-tween-one'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { IStaticComponent } from "./types"

import ClientImage1 from '../../assets/images/clients/1.png'
import ClientImage2 from '../../assets/images/clients/2.png'
import ClientImage3 from '../../assets/images/clients/3.png'
import ClientImage4 from '../../assets/images/clients/4.png'
import ClientImage5 from '../../assets/images/clients/5.png'
import ClientImage6 from '../../assets/images/clients/6.png'
import ClientImage7 from '../../assets/images/clients/7.png'
import ClientImage8 from '../../assets/images/clients/8.png'
import ClientImage9 from '../../assets/images/clients/9.png'
import ClientImage10 from '../../assets/images/clients/10.png'
import ClientImage11 from '../../assets/images/clients/11.png'
import ClientImage12 from '../../assets/images/clients/12.png'
import ClientImage13 from '../../assets/images/clients/13.png'
import ClientImage14 from '../../assets/images/clients/14.png'
import ClientImage15 from '../../assets/images/clients/15.png'
import ClientImage16 from '../../assets/images/clients/16.png'
import { Button, Card, Col, Divider, Row } from 'antd'
import { useEffect, useState } from 'react'
import { ClientDataSource } from './data.source'
import { useTranslation } from 'react-i18next'
import { postAnalyticClick } from '../../services/demo'

const Client: React.FC<IStaticComponent> = ({isMobile, isNotHome, withRecommendation, showModal, componentRef, ...rest }) => {
  const {t} = useTranslation()

  const wrapper = { className: `home-page-wrapper client-wrapper` }
  const overPack = { className: 'home-page client', playScale: 0.05 }

  const clients = [ClientImage1, ClientImage2, ClientImage3, ClientImage4, ClientImage5, ClientImage6, ClientImage7, ClientImage8, ClientImage9, ClientImage10, ClientImage11, ClientImage12, ClientImage13, ClientImage14, ClientImage15, ClientImage16]

  const [data, setData] = useState<any>()
  
  useEffect(() => {
    const initiate = async () => {
      const defaultLng = localStorage.getItem('@defaultLng')
      
      setData(defaultLng ? (defaultLng === 'en' ? ClientDataSource.en : ClientDataSource.id) : ClientDataSource.id)
    }

    initiate()
  }, [])

  const startSolutionAdvisor = async () => {
    await postAnalyticClick("advisor_clicks")
    window.location.href = "/solution"
  }

  return (
    <div {...rest} {...wrapper}>
      <OverPack {...overPack}>
        {!isNotHome && (
          <>
            <div className="title-wrapper">
              <h2 className="title-h1">{data && data.title}</h2>
            </div>
            <TweenOne
              animation={{ x: '+=30', opacity: 0, type: 'from' }}
              key="clients"
              className="content-clients"
            >
              {clients.map((item, key) => (
                <div key={key} className="client-item">
                  <img src={item} alt="EasyGo" height={50} />
                </div>
              ))}
            </TweenOne>
          </>
        )}
        
        {withRecommendation && (
          <div className="recommended" ref={componentRef}>
            <div className="title-wrapper">
              <h2 className="title-h1">{data && data.recommended}</h2>
            </div>
            <Row style={{justifyContent: 'center', alignItems: 'center'}}>
              {data && data.solutions.map((item: any, index: number) => (
                <Col lg={8} md={12} sm={24} className="card-col" key={index}>
                  <Card title={item.title} hoverable>
                    <div className="card-caption">{item.caption}</div>
                    <h2 className="title-h2">{item.subtitle}</h2>
                    <Divider />
                    <div className="card-top-container">{item.description}</div>
                    <Divider />
                    <div className="card-container">
                      <h3 className="title-h3">{item.footer}</h3>
                      <ul>
                        {item.includes.map((item: any, index: any) => <li key={index}>{item}</li>)}
                      </ul>
                    </div>
                    <div className="footer">
                      <Button type="primary" onClick={showModal}>
                        {t('common.demo')}
                      </Button>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        )}

        <div className="client-caption">
          {data && (
            <>
              {withRecommendation ? (
                <span className="ant-typography" dangerouslySetInnerHTML={{__html: data.footerDescription[1]}} />
              ) : (
                <span className="ant-typography" dangerouslySetInnerHTML={{__html: data.footerDescription[0]}} />
              )}
            </>
          )}
          <Button type="primary" onClick={() => startSolutionAdvisor()}>
            {t('common.advise')}
          </Button>
        </div>
      </OverPack>
    </div>
  )
}

export default Client