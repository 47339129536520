import {useTranslation} from "react-i18next"

import LT from '../../assets/images/logo.png'
import LubisTech from '../../assets/images/Lubis-Tech.png'
import TopBanner from '../../assets/videos/EasyGo_Trailer.gif'
import Facebook from '../../assets/images/facebook.svg'
import Instagram from '../../assets/images/instagram.svg'
import Phone from '../../assets/images/phone.svg'
import Whatsapp from '../../assets/images/whatsapp.png'

import Project from '../../assets/images/project.svg'
import Android from '../../assets/images/android.svg'
import iOS from '../../assets/images/apple.svg'
import Cloud from '../../assets/images/cloud-computing.svg'
import Shield from '../../assets/images/padlock.svg'
import Office from '../../assets/images/office.svg'

import Tracking from '../../assets/images/services/Delivery_Tracking.svg'
import Tools from '../../assets/images/services/Tools.svg'
import Increase from '../../assets/images/services/Increase.svg'
import Highway from '../../assets/images/services/Highway.svg'
import Complaint from '../../assets/images/services/Complaint.svg'
import BadIdea from '../../assets/images/services/Bad_Idea.svg'

import TableSelectIcon from '../../assets/images/TableSelect_icon.png'
import SalahIcon from '../../assets/images/Salah_icon.png'
import TentoriumIcon from '../../assets/images/tentorium-1024.png'

import TableSelect from '../../assets/images/TableSelect.png'
import Salah from '../../assets/images/Salah.png'
import Tentorium from '../../assets/images/tentorium-dark.png'
import { Link } from "react-router-dom"

import ITracking from '../../assets/images/interest/Tracking.png'
import Cost from '../../assets/images/interest/Cost.png'
import Sustainability from '../../assets/images/interest/Sustainability.png'
import Productivity from '../../assets/images/interest/Retro_Alarm_Clock.png'
import Safety from '../../assets/images/interest/Administrative_Tools.png'
import Navigational from '../../assets/images/interest/Shield_Locked.png'
import Fleet from '../../assets/images/interest/Taxi_Mobile_App.png'
import ITools from '../../assets/images/interest/Tools.png'
import IService from '../../assets/images/interest/Services.png'
import OrderManagement from '../../assets/images/interest/Order_Management.png'

import Recomm1 from '../../assets/images/recommendations/1.png'
import Recomm2 from '../../assets/images/recommendations/2.png'
import Recomm3 from '../../assets/images/recommendations/3.png'

import gear from '../../assets/images/tech/icon/gear.svg'
import settings from '../../assets/images/tech/icon/settings.svg'
import wifi from '../../assets/images/tech/icon/wifi.svg'
import clock from '../../assets/images/tech/icon/clock.svg'
import bell from '../../assets/images/tech/icon/bell.svg'
import phone from '../../assets/images/tech/icon/phone.svg'
import battery from '../../assets/images/tech/icon/battery.svg'
import truck from '../../assets/images/tech/icon/truck.svg'
import map from '../../assets/images/tech/icon/map.svg'

import Product1PNG from '../../assets/images/tech/detail-1.png'
import Product2PNG from '../../assets/images/tech/detail-2.png'
import Product3PNG from '../../assets/images/tech/detail-3.png'
import Product4PNG from '../../assets/images/tech/detail-4.png'

import Industry1PNG from "../../assets/images/industries/1.png"
import Industry2PNG from "../../assets/images/industries/2.png"
import Industry3PNG from "../../assets/images/industries/3.png"
import Industry4PNG from "../../assets/images/industries/4.png"
import Industry5PNG from "../../assets/images/industries/5.png"
import Industry6PNG from "../../assets/images/industries/6.png"
import Industry7PNG from "../../assets/images/industries/7.png"
import Industry8PNG from "../../assets/images/industries/8.png"
import Industry9PNG from "../../assets/images/industries/9.png"
import Industry10PNG from "../../assets/images/industries/10.png"
import Industry11PNG from "../../assets/images/industries/11.png"
import Industry12PNG from "../../assets/images/industries/12.png"
import Industry13PNG from "../../assets/images/industries/13.png"
import Industry14PNG from "../../assets/images/industries/14.png"

import id from "../../translations/id.json";
import en from "../../translations/en.json"

export const HeaderNavDataSource = (onClick: any) => {
  const {t} = useTranslation()
  let  lang: any 

  return {
    handleDemoModal: onClick,
    wrapper: { className: 'header3 home-page-wrapper' },
    page: { className: 'home-page' },
    logo: {
      className: 'header3-logo',
      children: LT,
    },
    Menu: {
      className: 'header3-menu',
      children: [
        {
          label: t('product'),
          key: 'product',
          className: (window.location.href.indexOf("/product") > -1 || window.location.href.indexOf("/fleet") > -1 || window.location.href.indexOf("technology") > -1) && 'ant-menu-item-selected',
          children: [
            {
              label: (<Link to='/fleet-management'>{t('fleet')}</Link>),
              key: 'product-fleet'
            },
            {
              label: (<Link to='/technology'>{t('technology')}</Link>),
              key: 'product-technology'
            }
          ]
        },
        {
          label: (<Link to='/solution'>{t('solution')}</Link>),
          key: 'solution',
          className: window.location.href.indexOf("/solution") > -1 && 'ant-menu-item-selected'
        },
        {
          label: (<Link to='/industries'>{t('industries')}</Link>),
          key: 'industries',
          className: window.location.href.indexOf("/industries") > -1 && 'ant-menu-item-selected'
        },
        {
          // label: (<Link to='/blog'>{t('resources')}</Link>),
          label: t('resources'),
          key: 'resources',
          className: (window.location.href.indexOf("/resources") > -1 || window.location.href.indexOf("/details") > -1) && 'ant-menu-item-selected',
          children: [
            {
              label: (<Link to='/resources/article'>{t('article')}</Link>),
              key: 'resources-article'
            },
            {
              label: (<Link to='/resources/e-book'>{t('ebook')}</Link>),
              key: 'resources-e-book'
            },
            {
              label: (<Link to='/resources/video'>{t('video')}</Link>),
              key: 'resources-video'
            }
          ]
        },
        {
          label: (<Link to='/career'>{t('career')}</Link>),
          key: 'career',
          className: window.location.href.indexOf("/career") > -1 && 'ant-menu-item-selected'
        },
        
      ],
    },
    mobileMenu: { className: 'header3-mobile-menu' },
  }  
}
export const BannerDataSource = (onClick: any, bannerType?: string) => {
  const {t} = useTranslation()
  
  const getTitle = () => {
    switch (bannerType) {
      case 'fleet-management':
        return t('fleet')
      case 'technology':
        return t('technology')
      case 'industries':
        return t('industries')
      case 'career':
        return t('career')
      default:
        return t('banner.title')
    }
  }

  const getDescription = () => {
    switch (bannerType) {
      case 'fleet-management':
        return t('fleet.description')
      case 'technology':
        return t('technology.description')
      case 'industries':
        return t('industries.description')
      case 'career':
        return t('career.description')
      default:
        return t('banner.description')
    }
  }

  return {
    wrapper: {
      id: 'home',
      className: 'home-page-wrapper banner5 element'
    },
    page: { className: 'home-page banner5-page' },
    childWrapper: {
      className: 'banner5-title-wrapper',
      children: [
        // { name: 'title', children: 'EasyGo', className: 'banner5-title' },
        {
          name: 'explain',
          className: 'banner5-explain',
          children: getTitle(),
        },
        {
          name: 'content',
          className: 'banner5-content',
          children: getDescription(),
        },
        {
          name: 'button',
          className: 'banner5-button-wrapper',
          children: {
            onClick: onClick,
            className: 'banner5-button',
            type: 'primary',
            children: t('common.demo'),
          },
        },
      ],
    },
    image: {
      className: 'banner5-image',
      children: TopBanner,
    },
  }
}
export const ServiceDataSource = (isNotHome?: boolean) => {
  const {t} = useTranslation()

  return {
    wrapper: {
      id: 'services',
      className: `home-page-wrapper content0-wrapper element ${isNotHome && 'is-not-home'}`
    },
    page: { className: 'home-page content0' },
    OverPack: { playScale: 0.3, className: '' },
    titleWrapper: {
      className: 'title-wrapper',
      children: [{ name: 'title', children: t('service.title') }],
    },
    childWrapper: {
      className: 'ant-row content0-block-wrapper',
      children: [
        {
          name: 'block0',
          className: 'content0-block',
          lg: 12,
          md: 12,
          xs: 24,
          children: {
            className: 'content0-block-item',
            children: [
              {
                name: 'image',
                className: 'content0-block-icon',
                children: Tracking,
              },
              
              {
                name: 'detail',
                className: '',
                children: [
                  {
                    name: 'title',
                    className: 'content0-block-title',
                    children: t('service.1.title'),
                  },
                  {
                    name: "desc",
                    className: "",
                    children: t('service.1.desc')
    
                  },
                  {
                    name: "items",
                    className: "",
                    children: localStorage.getItem('@defaultLng') === "id" ? id.common.service[1].items : en.common.service[1].items 
    
                  }
                ]
              },
              
            ],
          },
        },
        {
          name: 'block1',
          className: 'content0-block',
          lg: 12,
          md: 12,
          xs: 24,
          children: {
            className: 'content0-block-item',
            children: [
              {
                name: 'image',
                className: 'content0-block-icon',
                children: Tools,
              },

              {
                  name: 'detail',
                  className: "",
                  children: [
                    {
                      name: 'title',
                      className: 'content0-block-title',
                      children: t('service.2.title'),
                    },
                    {
                      name: 'desc',
                      className: "",
                      children: t('service.2.desc')
                    },
                    {
                      name: "items",
                      className: "",
                      children: localStorage.getItem('@defaultLng') === "id" ? id.common.service[2].items : en.common.service[2].items 
      
                    }
                  ]
              },
              
              
            ],
          },
        },
        {
          name: 'block2',
          className: 'content0-block',
          lg: 12,
          md: 12,
          xs: 24,
          children: {
            className: 'content0-block-item',
            children: [
              {
                name: 'image',
                className: 'content0-block-icon',
                children: BadIdea,
              },
              {
                name: 'detail',
                children: [
                  {
                    name: 'title',
                    className: 'content0-block-title',
                    children: t('service.3.title'),
                  },
                  {
                    name: 'desc',
                    className: "",
                    children: t('service.3.desc')
                  }
                ]
              },
            ],
          },
        },
        {
          name: 'block2',
          className: 'content0-block',
          lg: 12,
          md: 12,
          xs: 24,
          children: {
            className: 'content0-block-item',
            children: [
              {
                name: 'image',
                className: 'content0-block-icon',
                children: Increase,
              },
              {
                name: 'detail',
                children: [
                  {
                    name: 'title',
                    className: 'content0-block-title',
                    children: t('service.4.title'),
                  },
                  {
                    name: "items",
                    className: "",
                    children: localStorage.getItem('@defaultLng') === "id" ? id.common.service[4].items : en.common.service[4].items
    
                  }
                ]
              },
            ],
          },
        },
        {
          name: 'block2',
          className: 'content0-block',
          lg: 12,
          md: 12,
          xs: 24,
          children: {
            className: 'content0-block-item',
            children: [
              {
                name: 'image',
                className: 'content0-block-icon',
                children: Complaint,
              },
              {
                name: 'detail',
                children: [
                  {
                    name: 'title',
                    className: 'content0-block-title',
                    children: t('service.5.title'),
                  },
    
                  {
                    name: 'desc',
                    className: '',
                    children: t('service.5.desc'),
                  },
                ]
              },
              
              
            ],
          },
        },
        {
          name: 'block2',
          className: 'content0-block',
          lg: 12,
          md: 12,
          xs: 24,
          children: {
            className: 'content0-block-item',
            children: [
              {
                name: 'image',
                className: 'content0-block-icon',
                children: Highway,
              },
              {
                name: 'detail',
                children: [
                  {
                    name: 'title',
                    className: 'content0-block-title',
                    children: t('service.6.title'),
                  },
                  {
                    name: "items",
                    className: "",
                    children: localStorage.getItem('@defaultLng') === "id" ? id.common.service[6].items : en.common.service[6].items
    
                  }
                ]
              },
              
              
            ],
          },
        },
      ],
    },
  }
}
export const AboutDataSource = {
  wrapper: {
    id: 'about',
    className: 'home-page-wrapper content1-wrapper'
  },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: LubisTech,
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: 'EasyGo' },
  content: {
    className: 'content1-content',
    children:
      'We are a software development company from Indonesia and we are passionate about delivering strong, robust software solutions to our clients. We specialise in fast, clean, and modern technology that can solve many of the problems that businesses face as they grow and adapt to a changing marketplace.',
  },
}
export const PortfolioDataSource = {
  wrapper: {
    id: 'portfolio',
    className: 'home-page-wrapper content7-wrapper'
  },
  page: { className: 'home-page content7' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: 'Kalkulator penghemat',
        className: 'title-h1',
      },
      { name: 'content', children: 'Berapa rata-rata pengeluaran bulanan Anda untuk...' },
    ],
  },
  tabsWrapper: { className: 'content7-tabs-wrapper' },
  block: {
    children: [
      {
        name: 'block0',
        tag: {
          className: 'content7-tag',
          text: { children: 'Mobile Application', className: 'content7-tag-name' },
          icon: { children: 'mobile' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            childrenList: [
              {title: "Table Select", description: "Restaurant Booking System", icon: TableSelectIcon, img: TableSelect},
              {title: "Salah", description: "Muslim Prayer & Lifestyles", icon: SalahIcon, img: Salah},
              {title: "Tentorium", description: "E-Learning System", icon: TentoriumIcon, img: Tentorium},
            ]
          },
          img: {
            className: 'content7-img',
            children:
              'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
            md: 10,
            xs: 24,
          },
        },
      },
      {
        name: 'block1',
        tag: {
          className: 'content7-tag',
          icon: { children: 'web' },
          text: { className: 'content7-tag-name', children: 'Web Application' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            childrenList: [
              {title: "Doctrina", description: "E-Learning Admin Dashboard"},
              {title: "Arcanum Studio", description: "E-Commerce Website"},
            ]
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children:
              'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
          },
        },
      },
    ],
  },
}
export const Feature0DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: 'What We Do',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: 'If you have a problem that needed to be solved, do not hesitate to get in touch with us. Our main services are listed below;',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: Project,
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Software Development' },
          content: {
            className: 'content3-content',
            children:
              'We build both Native and Web-based systems using modern programming languages.',
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: Android,
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Android App Development' },
          content: {
            className: 'content3-content',
            children:
              'We build Android application using modern programming languages, Objective-C, React Native, etc.',
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: iOS,
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'iOS App Development' },
          content: {
            className: 'content3-content',
            children:
              'We build iOS application using modern programming languages, Objective-C, React Native, etc.',
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: Shield,
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Security Audits' },
          content: {
            className: 'content3-content',
            children:
              'Security is the most priority for all of our systems.',
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: Cloud,
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Cloud Computing Solutions' },
          content: {
            className: 'content3-content',
            children:
              'We help clients to meet their goal and secure systems by using modern solutions such as, AWS, Azure, and GCP.',
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: Office,
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Microsoft 365' },
          content: {
            className: 'content3-content',
            children:
              'We build Add-Ins for Microsoft Word, Excel, Powerpoint, and Outlook and integrate it with your system.',
          },
        },
      },
    ],
  },
}
export const ContactDataSource = {
  wrapper: {
    id: 'contact',
    className: 'home-page-wrapper content3-wrapper'
  },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: 'Contact Us',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: 'If you have a problem that needed to be solved, do not hesitate to get in touch with us. Contact us using form below;',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: Project,
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Software Development' },
          content: {
            className: 'content3-content',
            children:
              'We build both Native and Web-based systems using modern programming languages.',
          },
        },
      },
    ],
  },
}
export const FooterDataSource = {
  wrapper: { className: 'home-page-wrapper footer2-wrapper' },
  OverPack: { className: 'home-page footer2', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: [
      {
        name: 'image',
        children: LT,
        className: 'copyright-logo',
      },
      {
        name: 'group',
        children: 'EasyGo',
        className: 'copyright-group',
      },
      {
        name: 'image2',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/fgGmQUfiUfSBfvsQpfOj.svg',
        className: 'copyright-line',
      },
      {
        name: 'copyright',
        children: 'Copyright © 2021 All rights reserved.',
        className: 'copyright-text',
      },
    ],
  },
  links: {
    className: 'links',
    children: [
      {
        name: 'phone',
        href: 'tel:+6282273138788',
        title: 'Call Us',
        className: 'links-phone',
        children: Phone
      },
      {
        name: 'whatsapp',
        href: 'https://wa.me/6282273138788',
        title: 'Whatsapp Us',
        className: 'links-whatsapp',
        children: Whatsapp
      },
      {
        name: 'facebook',
        href: 'https://www.facebook.com/arcanum.id',
        title: 'Facebook Arcanum Tech',
        className: 'links-facebook',
        children: Facebook
      },
      {
        name: 'instagram',
        href: 'https://www.instagram.com/arcanum.id/',
        title: 'Instagram Arcanum Tech',
        className: 'links-instagram',
        children: Instagram,
      },
    ],
  },
}

export const FAQDataSource = {
  en: [
    {
      question: "What is vehicle tracking?",
      answer: `
        <p>Vehicle tracking works by combining automatic vehicle location programming with
        software that is able to collect and maintain such data. It's triple function of acquiring,
        storing, and, ultimately, processing location data allows trackers to get a vivid understanding
        of where a vehicle is located, all in real time.</p>
        
        <p>By delivering real-time and historical locations of the cars, vans, or trucks in a business’s
        fleet, vehicle tracking solutions help the fleet manager to both manage their workflow
        effectively in the moment and take better informed action to improve it in future.</p>
      `
    },
    {
      question: "What is a vehicle tracking system?",
      answer: `
        <p>Vehicle tracking works just as the name suggests. Using a global positioning
        system, users are able to monitor fleet vehicle locations as it relates to their geographical
        location.</p>
        
        <p>Depending on the nature of the device, the registered information may be stored
         internally or broadcast in real time to a cooperating program. Some trackers trackers use
         cellular data (such as those found in most contemporary
         smartphones), while others use
         satellite signals to store and project the information.</p>
        
        <p>Satellite-
         managed tracking systems require no phone service to provide real-time
         updates. Consequently,
         they are well-equipped
         to handle more challenging routes in which acquiring a signal is a rare and unlikely occurrence. In either case, however, EasyGo systems are dependably accurate.</p>
      `
    },
    {
      question: "Why use a vehicle tracking system?",
      answer: `
        <p>When it comes to fleet management, the benefits of a vehicle tracking system are many.
        Vehicle tracking allows fleet managers and supervisors to know where their drivers and
        cargo are at all times—a very helpful feature for handling unexpected situations and a powerful tool for ensuring some of a business' most valuable resources are kept secure,
        safe, and in good condition.</p>
        
        <p>Vehicle tracking systems can also help you set more accurate estimated times of arrival,
        particularly
         if the solution you are using offers accurate traffic information. With that
        functionality,
         you can adapt to unexpected jams and alert your driver to alter their route.
         A reliable vehicle tracking solution can also provide proof of delivery, if required, while
         also giving you insights into how much time is being spent with customers.</p>
        
        <p>Driver communication
         and performance evaluation are both also improved by vehicle tracking. For example, a vehicle tracking solution could provide insight into how drivers
         are behaving behind the wheel that can be a good basis for focused coaching and improvements.</p>
        
        <p>Vehicle tracking can also help with route planning. It could provide insights on how to select faster, more efficient routes in future, or guarantee that drivers are remaining
         within compliance as it relates to laws dictating how many hours they are permitted to
         work consecutively
         in a single shift.</p>
        
        <p>In short, the service allows a level of fleet oversight never before realized by legacy technologies.</p>
      `
    },
    {
      question: "What are the benefits of tracking and tracing?",
      answer: `
        <p>Tracing previous trips your vehicles have made is a great way to identify where savings
        can be made and greater levels of efficiency can be created in your workflow. A
         reliable vehicle tracking system will accurately record hours worked and hours driven.
         Users can also see the historical position of their vehicles, in some cases going back as
         far as 90 days in the past.</p>
        
        <p>With this information, you can better assess how your fleet is performing and how your resources are being utilized. Patterns and anomalies can be identified in how your
         vehicles move day to day, so you can make intelligent choices as to how to improve.</p>
      `
    },
    {
      question: "How much does vehicle tracking cost?",
      answer: `
        <p>How much vehicle tracking costs strongly depends on the type of plan that you select. Things like the number of vehicles you wish to track will impact the overall cost of your vehicle tracking solution.</p>

        <p>However, while there are certainly costs built into adopting a vehicle tracking system,
         there are also savings associated with the service as well. For example, performance improvements
         linked to increased oversight may save fleets money on mileage, time, and other expenses that are associated with underwhelming
         efficiency.</p>
      `
    },
    {
      question: "How does a vehicle tracking system work?",
      answer: `
        <p>Vehicle tracking operates through global positioning systems. To start, vehicles are outfitted with hardware devices that serve as receivers for important satellite signals. Layperson vehicle tracking may not require anything more than a smartphone, while
        other systems could involve an installation
        of some kind.</p>
       
       <p>EasyGo devices remains in constant communication
        with the satellite while the vehicle is in motion. In addition to including information about the specific location of a vehicle, many tracking systems are also able to furnish other key data points, such as direction and speed.</p>
      `
    },
  ],
  id: [
    {
      question: "Apa itu pelacakan kendaraan?",
      answer: `
        <p>Pelacakan kendaraan bekerja dengan menggabungkan pemrograman lokasi kendaraan otomatis dengan perangkat lunak yang mampu mengumpulkan dan memelihara data tersebut. Ini tiga fungsi memperoleh, menyimpan, dan, pada akhirnya, memproses data lokasi memungkinkan pelacak mendapatkan pemahaman yang jelas di mana kendaraan berada, semuanya dalam waktu nyata.</p>
        <p>Dengan memberikan lokasi real-time dan historis dari mobil, van, atau truk dalam bisnis' armada, solusi pelacakan kendaraan membantu manajer armada untuk mengelola alur kerja mereka efektif pada saat ini dan mengambil tindakan informasi yang lebih baik untuk memperbaikinya di masa depan.</p>
      `
    },
    {
      question: "Apa itu sistem pelacakan kendaraan?",
      answer: `
        <p>Pelacakan kendaraan berfungsi seperti namanya. Menggunakan pemosisian global sistem, pengguna dapat memantau lokasi armada kendaraan yang berkaitan dengan geografis mereka lokasi.</p>
        <p>Tergantung pada sifat perangkat, informasi yang terdaftar dapat disimpan internal atau disiarkan secara real time ke program yang bekerja sama. Beberapa pelacak pelacak menggunakan data seluler (seperti yang ditemukan di sebagian besar kontemporer smartphone), sementara yang lain menggunakan sinyal satelit untuk menyimpan dan memproyeksikan informasi.</p>
        <p>Satelit- sistem pelacakan terkelola tidak memerlukan layanan telepon untuk menyediakan waktu nyata pembaruan. Akibatnya, mereka dilengkapi dengan baik untuk menangani rute yang lebih menantang di mana memperoleh sinyal adalah kejadian langka dan tidak mungkin. Namun, dalam kedua kasus tersebut, sistem EasyGo sangat akurat.</p>
      `
    },
    {
      question: "Mengapa menggunakan sistem pelacakan kendaraan?",
      answer: `
        <p>Ketika datang ke manajemen armada, manfaat dari sistem pelacakan kendaraan banyak. Pelacakan kendaraan memungkinkan manajer armada dan penyelia untuk mengetahui di mana pengemudi dan kargo selalu ada—fitur yang sangat membantu untuk menangani situasi tak terduga dan alat yang ampuh untuk memastikan beberapa sumber daya bisnis yang paling berharga tetap aman, aman, dan dalam kondisi baik.</p>
        <p>Sistem pelacakan kendaraan juga dapat membantu Anda mengatur perkiraan waktu kedatangan yang lebih akurat, khususnya jika solusi yang Anda gunakan menawarkan informasi lalu lintas yang akurat. Dengan itu Kegunaan, Anda dapat beradaptasi dengan kemacetan yang tidak terduga dan memperingatkan pengemudi Anda untuk mengubah rute mereka. Solusi pelacakan kendaraan yang andal juga dapat memberikan bukti pengiriman, jika diperlukan, sementara juga memberi Anda wawasan tentang berapa banyak waktu yang dihabiskan dengan pelanggan.</p>
        <p>Komunikasi pengemudi dan evaluasi kinerja keduanya juga ditingkatkan dengan pelacakan kendaraan. Misalnya, solusi pelacakan kendaraan dapat memberikan wawasan tentang bagaimana pengemudi berperilaku di belakang kemudi yang dapat menjadi dasar yang baik untuk pembinaan dan peningkatan yang terfokus.</p>
        <p>Pelacakan kendaraan juga dapat membantu perencanaan rute. Ini dapat memberikan wawasan tentang cara memilih rute yang lebih cepat dan lebih efisien di masa depan, atau menjamin bahwa pengemudi tetap tinggal dalam kepatuhan karena terkait dengan undang-undang yang menentukan berapa jam mereka diizinkan untuk bekerja secara berurutan dalam satu shift.</p>
        <p>Singkatnya, layanan ini memungkinkan tingkat pengawasan armada yang belum pernah dilakukan sebelumnya oleh teknologi lama.</p>
      `
    },
    {
      question: "Apa manfaat pelacakan dan penelusuran?",
      answer: `
        <p>Menelusuri perjalanan sebelumnya yang telah dilakukan kendaraan Anda adalah cara yang bagus untuk mengidentifikasi di mana penghematan dapat dibuat dan tingkat efisiensi yang lebih besar dapat dibuat dalam alur kerja Anda. SEBUAH sistem pelacakan kendaraan yang andal akan secara akurat mencatat jam kerja dan jam kerja. Pengguna juga dapat melihat posisi historis kendaraan mereka, dalam beberapa kasus akan kembali sebagai sejauh 90 hari di masa lalu.</p>
        <p>Dengan informasi ini, Anda dapat menilai kinerja armada Anda dengan lebih baik dan bagaimana sumber daya Anda digunakan. Pola dan anomali dapat diidentifikasi dalam cara Anda kendaraan bergerak dari hari ke hari, sehingga Anda dapat membuat pilihan cerdas tentang cara meningkatkannya.</p>
      `
    },
    {
      question: "Berapa biaya pelacakan kendaraan?",
      answer: `
        <p>Berapa biaya pelacakan kendaraan sangat tergantung pada jenis paket yang Anda pilih. Hal-hal seperti jumlah kendaraan yang ingin Anda lacak akan memengaruhi biaya keseluruhan solusi pelacakan kendaraan Anda.</p>
        <p>Namun, meskipun ada biaya yang harus dikeluarkan untuk mengadopsi sistem pelacakan kendaraan, ada juga penghematan yang terkait dengan layanan ini. Misalnya, peningkatan kinerja terkait dengan peningkatan pengawasan dapat menghemat uang armada untuk jarak tempuh, waktu, dan biaya lain yang terkait dengan underwhelming efisiensi.</p>
      `
    },
    {
      question: "Bagaimana cara kerja sistem pelacakan kendaraan?",
      answer: `
        <p>Pelacakan kendaraan beroperasi melalui sistem penentuan posisi global. Untuk memulai, kendaraan dilengkapi dengan perangkat keras yang berfungsi sebagai penerima sinyal satelit penting. Pelacakan kendaraan orang awam mungkin tidak memerlukan apa pun selain smartphone, sementara sistem lain dapat melibatkan instalasi dari beberapa jenis.</p>
        <p>Perangkat EasyGo tetap berkomunikasi secara konstan dengan satelit saat kendaraan sedang bergerak. Selain memasukkan informasi tentang lokasi spesifik kendaraan, banyak sistem pelacakan juga dapat memberikan titik data penting lainnya, seperti arah dan kecepatan.</p>
      `
    },
  ]
}

export const ProductTechDataSource = {
  en: {
    title: "Fleet Management Products",
    caption: "EasyGo functionality can be expanded by adding various in-vehicle telematics devices and driver terminals.",
    description: "Our products are designed to help your teams work better together. We connect your vehicles and mobile workforce to the office. And when everyone's connected, teams are better informed and smarter decisions are made. How do we do that? By making things easy. Easy for the driver, easy for the dispatcher and easy for management. EasyGo ensures the information is always easily accessible.",
  },
  id: {
    title: "Prodak Optimisasi Armada",
    caption: "Fungsi EasyGo dapat diperluas dengan menambahkan berbagai perangkat telematika di dalam kendaraan dan terminal driver.",
    description: "Produk kami dirancang untuk membantu tim Anda bekerja sama dengan lebih baik. Kami menghubungkan kendaraan dan tenaga kerja mobile Anda ke kantor. Dan ketika semua orang terhubung, tim mendapat informasi yang lebih baik dan keputusan yang lebih cerdas dibuat. Bagaimana kita melakukannya? Dengan membuat segalanya menjadi mudah. Mudah bagi pengemudi, mudah bagi petugas operator, dan mudah dikelola. EasyGo memastikan informasi selalu mudah diakses.",
  }
}

export const ClientDataSource = {
  en: {
    title: "Our Clients",
    recommended: "Recommended solutions",
    solutions: [
      {
        title: "For Small Businesses",
        caption: "100 vehicles or less",
        subtitle: "Standard",
        description: "Small businesses can streamline the way they manage their fleets with easy-to-use tools that can help you cut costs, increase productivity, simplify maintenance and promote safety.",
        footer: "Includes",
        includes: ["TMS (Standard)", "Dashboard (Standard)", "Real Time Tracking", "System Integration"]
      },
      {
        title: "For Medium to Corporate",
        caption: "Best for any fleet size",
        subtitle: "Premium",
        description: "Companies of all sizes can get more out of their fleet with a comprehensive suite of solutions designed to help you better manage your vehicles, drivers, equipment and jobs.",
        footer: "Includes",
        includes: ["TMS (Advanced)", "Dashboard (Standard)", "Real Time Tracking", "Full System Training", "Maintenance System", "Reporting"]
      },
      // {
      //   title: "For enterprise",
      //   caption: "Good for coporate fleet",
      //   subtitle: "Premium",
      //   description: "Enterprise size companies can get more out of their fleet with a comprehensive suite of solutions designed to help you better manage your vehicles, drivers, equipment and jobs.",
      //   footer: "Includes",
      //   includes: ["TMS (Advanced)", "Tracking Device", "Driver Terminal"]
      // },
    ],
    footerDescription: [
      "Return on investmentin six to nine months on average. EasyGo can help your business. Want to find out how? Give it a try. Get a demo or speak to a specialist today.",
      `Find out what type of solution fits your company best.<br/>
      Use our Solution Advisor to get your individual product recommendation`,
    ]
  },
  id: {
    title: "Klien Kami",
    recommended: "Rekomendasi",
    solutions: [
      {
        title: "Untuk bisnis kecil",
        caption: "100 kendaraan / kurang",
        subtitle: "Standar",
        description: "Usaha kecil dapat merampingkan cara mereka mengelola armada mereka dengan alat yang mudah digunakan yang dapat membantu Anda memangkas biaya.",
        footer: "Yang didapatkan",
        includes: ["TMS (Standard)", "Dashboard (Standard)", "Real Time Tracking", "Integrasi Sistem"]
      },
      {
        title: "Untuk Menengah - Korporat",
        caption: "Untuk semua ukuran",
        subtitle: "Premium",
        description: "Perusahaan dari semua ukuran bisa dapat lebih banyak dari armada dengan rangkaian solusi komprehensif yang dirancang untuk membantu Anda mengelola kendaraan.",
        footer: "Yang didapatkan",
        includes: ["TMS (Advanced)", "Dashboard (Standard)", "Real Time Tracking", "Full System Training", "Maintenance System", "Reporting"]
      },
      // {
      //   title: "Untuk perusahaan",
      //   caption: "Bagus untuk perusahaan",
      //   subtitle: "Premium",
      //   description: "Perusahaan dapat memaksimalkan armada mereka dengan rangkaian solusi komprehensif yang dirancang untuk membantu Anda mengelola kendaraan.",
      //   footer: "Yang didapatkan",
      //   includes: ["TMS (Advanced)", "Tracking Device", "Driver Terminal"]
      // },
    ],
    footerDescription: [
      "Pengembalian investasi rata-rata dalam enam hingga sembilan bulan, EasyGo dapat membantu bisnis Anda. Ingin tahu caranya? Cobalah. Dapatkan demo atau berbicara dengan spesialis hari ini.",
      "Cari tahu jenis solusi apa yang paling sesuai dengan perusahaan Anda Gunakan Penasihat Solusi kami untuk mendapatkan rekomendasi produk individual Anda",
    ]
  }
}

export const IndustryDataSource = {
  en: {
    title: "Type of Industries",
    secondTitle: "Indonesia's no 1 choice!",
    secondSubtitle: "Already over 1,000 clients are achieving more with EasyGo",
    list: [
      "Logistic & Distribution",
      "Cold Chain",
      "Public Transportation",
      "FMCG (Fast Moving Customer Goods)",
      "Rental Service",
      "Cash in Transit",
      "Heavy Machinery",
      "Courier Service",
      "B3 (Limbah/Waste Transportation)",
      "Farmasi",
      "Oil & Mining",
      "Plantation (Perkebunan)",
      "Tourism",
      "Container (Depo)"
    ]
  },
  id: {
    title: "Jenis industri",
    secondTitle: "Nomor 1 pilihan Indonesia",
    secondSubtitle: "Sudah lebih dari 1.000 klien mencapai lebih banyak dengan EasyGo",
    list: [
      "Logistic & Distribution",
      "Cold Chain",
      "Public Transportation",
      "FMCG (Fast Moving Customer Goods)",
      "Rental Service",
      "Cash in Transit",
      "Heavy Machinery",
      "Courier Service",
      "B3 (Limbah/Waste Transportation)",
      "Farmasi",
      "Oil & Mining",
      "Plantation (Perkebunan)",
      "Tourism",
      "Container (Depo)"
    ],
  }
}

export const SolutionDataSource = {
  en: {
    title: "Solution Advisor",
    description: "Get the right solution for your business",
    steps: [
      "Fleet Size",
      "Type of Industries",
      "Individual requirements",
      "Your recommendation"
    ],
    step1Title: "Select the type of indutries you use the most.",
    step2Title: "How many vehicles are in your fleet?",
    step3Title: "Which products are you interested in?",
    vehicleTypes: [
      {
        title: "Logistic & Distribution",
        image: Industry1PNG,
      },
      {
        title: "Cold Chain",
        image: Industry2PNG,
      },
      {
        title: "Public Transportation",
        image: Industry3PNG,
      },
      {
        title: "FMCG (Fast Moving Customer Goods)",
        image: Industry4PNG,
      },
      {
        title: "Rental Service",
        image: Industry5PNG,
      },
      {
        title: "Cash in Transit",
        image: Industry6PNG,
      },
      {
        title: "Heavy Machinery",
        image: Industry7PNG,
      },
      {
        title: "Courier Service",
        image: Industry8PNG,
      },
      {
        title: "B3 (Waste Transportation)",
        image: Industry9PNG,
      },
      {
        title: "Farmasi",
        image: Industry10PNG,
      },
      {
        title: "Oil & Mining",
        image: Industry11PNG,
      },
      {
        title: "Plantation",
        image: Industry12PNG,
      },
      {
        title: "Tourism",
        image: Industry13PNG,
      },
      {
        title: "Container",
        image: Industry14PNG,
      }
    ],
    interests: [
      {
        title: "Tracking",
        image: ITracking,
      },
      {
        title: "Operational Cost",
        image: Cost,
      },
      {
        title: "Sustanbility (Improve)",
        image: Sustainability,
      },
      {
        title: "Productivity (increase)",
        image: Productivity,
      },
      {
        title: "Safety & Compliance",
        image: Safety,
      },
      {
        title: "Navigational Safety",
        image: Navigational,
      },
      {
        title: "Fleet Management",
        image: Fleet,
      },
      {
        title: "Scheduled Maintenance",
        image: ITools,
      },
      {
        title: "System Integration",
        image: IService,
      },
      {
        title: "Order Management",
        image: OrderManagement,
      },
      {
        title: "Tracking (Real Time Monitoring)",
        image: ITracking,
      },
      {
        title: "Mobile Digital Video Recorder (MDVR)",
        image: Fleet,
      },
      {
        title: "Customize Development",
        image: Safety,
      }
    ],
    step1Options: [
      { label: '1 - 25', value: '1 - 25' },
      { label: '25 - 50', value: '25 - 50' },
      { label: '50 - 100', value: '50 - 100' },
      { label: '100 - 500', value: '100 - 500' },
      { label: 'More than 500', value: 'More than 500' },
    ],
    recommendations: [
      {
        title: "TMS",
        image: Recomm1,
        description: "Let drivers compete for positions on the company leaderboard by driving safely, productively and efficiently. Connect driver and vehicle data to locate your crew and help coach them on safe driving behaviors."
      },
      {
        title: "Vehicle Tracking Devices",
        image: Recomm2,
        description: "For fleet owners looking for a vehicle tracking solution that is simple to use and addresses common pain points such as high fuel costs, worker productivity, driver safety, compliance and asset security."
      },
      {
        title: "Smart Dashboard",
        image: Recomm3,
        description: "Our TMS dashboard allows you to take your business wherever you go. View vehicles and assets on a live map, locate a driver, see vehicle activity and route history, and receive alerts on driver behaviors."
      },
    ]
  },
  id: {
    title: "Solusi Perencanaan",
    description: "Dapatkan Solusi Untuk Bisnis Anda",
    steps: [
      "Jenis Industri",
      "Jumlah Armada",
      "Ketentuan Armada",
      "Rekomendasi untuk kamu"
    ],
    step1Title: "Pilih jenis industri yang anda gunakan",
    step2Title: "Berapa banyak armada anda?",
    step3Title: "Produk mana yang menarik perhatian anda?",
    vehicleTypes: [
      {
        title: "Logistic & Distribution",
        image: Industry1PNG,
      },
      {
        title: "Cold Chain",
        image: Industry2PNG,
      },
      {
        title: "Public Transportation",
        image: Industry3PNG,
      },
      {
        title: "FMCG (Fast Moving Customer Goods)",
        image: Industry4PNG,
      },
      {
        title: "Rental Service",
        image: Industry5PNG,
      },
      {
        title: "Cash in Transit",
        image: Industry6PNG,
      },
      {
        title: "Heavy Machinery",
        image: Industry7PNG,
      },
      {
        title: "Courier Service",
        image: Industry8PNG,
      },
      {
        title: "B3 (Limbah/Waste Transportation)",
        image: Industry9PNG,
      },
      {
        title: "Farmasi",
        image: Industry10PNG,
      },
      {
        title: "Oil & Mining",
        image: Industry11PNG,
      },
      {
        title: "Plantation (Perkebunan)",
        image: Industry12PNG,
      },
      {
        title: "Tourism",
        image: Industry13PNG,
      },
      {
        title: "Container (Depo)",
        image: Industry14PNG,
      }
    ],
    interests: [
      {
        title: "Pelacakan",
        image: ITracking,
      },
      {
        title: "Biaya Operasional",
        image: Cost,
      },
      {
        title: "Efisiensi",
        image: Sustainability,
      },
      {
        title: "Meningkatkan Produktifitas",
        image: Productivity,
      },
      {
        title: "Keamanan & Berkelanjutan",
        image: Safety,
      },
      {
        title: "Keamanan Operasional",
        image: Navigational,
      },
      {
        title: "Manajemen Armada",
        image: Fleet,
      },
      {
        title: "Jadwal Pemeliharaan",
        image: ITools,
      },
      {
        title: "Integrasi Sistem",
        image: IService,
      },
      {
        title: "Order Management",
        image: OrderManagement,
      },
      {
        title: "Tracking (Real Time Monitoring)",
        image: ITracking,
      },
      {
        title: "Mobile Digital Video Recorder (MDVR)",
        image: Fleet,
      },
      {
        title: "Customize Development",
        image: Safety,
      }
    ],
    step1Options: [
      { label: '1 - 25', value: '1 - 25' },
      { label: '25 - 50', value: '25 - 50' },
      { label: '50 - 100', value: '50 - 100' },
      { label: '100 - 500', value: '100 - 500' },
      { label: 'Lebih dari 500', value: 'Lebih dari 500' },
    ],
    recommendations: [
      {
        title: "TMS",
        image: Recomm1,
        description: "Biarkan pengemudi bersaing untuk menjadi pengemudi teraman, produktif, dan efisien. Hubungkan data pengemudi dan kendaraan untuk membantu dan melatih mereka tentang perilaku mengemudi yang aman."
      },
      {
        title: "Vehicle Tracking Devices",
        image: Recomm2,
        description: "Untuk pemilik armada yang mencari solusi pelacakan kendaraan yang mudah digunakan dan mengatasi masalah umum seperti biaya bahan bakar yang tinggi, produktivitas pekerja, keselamatan pengemudi, kepatuhan, dan keamanan aset."
      },
      {
        title: "Smart Dashboard",
        image: Recomm3,
        description: "Dasbor TMS kami memungkinkan Anda membawa bisnis ke mana pun Anda pergi. Lihat kendaraan dan aset di peta langsung, temukan pengemudi, lihat aktivitas kendaraan dan riwayat rute, dan terima peringatan tentang perilaku pengemudi."
      },
    ]
  }
}

export const TechDetailSource = {
  en: {
    seal: {
      image: Product1PNG,
      name: 'E-seal ( Elektronic Seal )',
      description: 'This electronic seal from EasyGo is the complete solution to upgrade safe delivery of goods. For keep goods safe, E-Seal EasyGo using two electronic locks, namely RFID Card Technology, where seals can only be unlocked by RFID card, and unlock by system.',
      description2: null,
      data: [
        {
          name: 'Information on Features & Benefits of E-Seal 38',
          type: 'icon',
          data: [
            { icon: map, name: 'Realtime Tracking' },
            { icon: truck, name: 'Support to Delivery Order (DO) System Multi Asal Multi Tujuan' },
            { icon: battery, name: 'Long Life Battery 5.000 mAh' },
            { icon: phone, name: 'Electronic lock by : Proses Lock - Unlock by RFID Technology' },
            { icon: bell, name: 'Early warning system Email & Telegram ( Sosial Media )' },
            { icon: clock , name: 'Lock - String Alarm' },
            { icon: wifi, name: 'Connecting With 4G' },
            { icon: gear, name: 'Integration & Development System' },
            { icon: settings, name: 'Remote System Remote Upgrade by OTA' },
          ]
        }
      ]
    },
    tms: {
      image: Product4PNG,
      name: 'EasyGo TMS',
      description: `It's time for your business to switch to digitization, by using IoT (Internet of Things) Technology based GPS tracker (GPS Safety) that complies with regulations government.`,
      description2: null,
      data: [
        {
          name: 'Fleet Management',
          type: 'list',
          data: [
            { name: 'Maintenance Management Cost' },
            { name: 'Management ( Transaction ) Target' },
            { name: 'Per-Unit Route Management / Ritase' },
            { name: '(Utilisasi & Route Geofence)' },
            { name: 'Route Planner' },
            { name: 'User Access Management' },
            { name: 'Add On' }
          ]
        },
        {
          name: 'Order Management',
          type: 'list',
          data: [
            { name: 'Client Management' },
            { name: 'Pricing Management' },
            { name: 'Project Management' },
            { name: 'Job Order Management' },
            { name: 'Dashboard Order' },
            { name: 'Management' },
            { name: 'Planning Order &' },
            { name: 'Public Community' },
            { name: 'Target Order' },
            { name: 'Cost Management' },
            { name: 'Invoice Integrated' },
            { name: 'With Order' }
          ]
        },
        {
          name: 'Invoicing',
          type: 'list',
          data: [
            { name: 'Integrated Client ERP' }
          ]
        },
        {
          name: 'Driver/HR Management',
          type: 'list',
          data: [
            { name: 'DMS Report' },
            { name: 'E-POD' },
            { name: 'User/Hp Tracking' },
            { name: 'Attendance' },
            { name: 'Employee Schedule' },
            { name: 'Employee & Driver Activity' },
            { name: 'Cost Management' },
            { name: 'Report' },
            { name: 'KPI Driver' }
          ]
        },
        {
          name: 'Sales And Planning',
          type: 'list',
          data: [
            { name: 'TBD Application HR' },
            { name: 'Management' },
            { name: 'Sales Target' },
            { name: 'Daily Activity' },
            { name: 'KPI Sales' },
            { name: 'DMS Report' }
          ]
        },
        {
          name: 'Logistic Monitoring',
          type: 'list',
          data: [
            { name: 'Realtime Monitoring' },
            { name: 'Multi Platform' },
            { name: 'Alert Notification' },
            { name: 'Route Violation' },
            { name: 'Playback History' },
            { name: 'Geofence/PO/Checkpoint' },
            { name: 'Smart Dashboard' },
            { name: 'Analytical Report' }
          ]
        }
      ]
    },
    tracking: {
      image: Product3PNG,
      name: 'EasyGo Vehicle Tracking Devices',
      description: 'With EasyGo software, you can find out the location of your vehicle and can collect your vehicle fleet data. This vehicle fleet management solution can pinpoint accurately time spent, when, where, and how forms optimal utilization of your vehicle fleet.',
      description2: `Vehicle tracking from EasyGo can provide real-time location of every vehicle in your vehicle fleet. It is possible you to adapt quickly to new developments so able to make more effective and efficient decisions. tracking system EasyGo vehicles can provide you with information regarding which vehicle is closest to be given the latest task, as well giving you instant access to the vehicle, as well as behavior information the driver in driving the vehicle, such as mileage, limits speed, even the driver's way of braking in stopping the vehicle. This vehicle tracking can give you that information better, and save costs thereby making your business more efficient.`,
      data: []
    },
    dashboard: {
      image: Product2PNG,
      name: 'EasyGo Smart Dashboard',
      description: null,
      description2: null,
      data: [
        {
          name: 'Dashboard Executive Summary',
          type: 'desc',
          data: [
            { name: 'An Information system that displays the value of revenue, and profit in the current month with the condition of how many times the fleet makes delivery orders / delivery. The Executive Summary dashboard also displays past costs issued to the fleet. Facilitate the Top Level Management to see the Performance of the Business Performance that is being carried out.' },
          ]
        },
        {
          name: 'Dashboard Delivery Order',
          type: 'desc',
          data: [
            { name: 'An information system that presents fleet activity about which units are currently carrying DO and which are not carrying DO with distinguished various types or types of fleets that can be adjusted. In addition, there is information about the Utilization Report or Truck that can help to optimize fleet Utilization Trips and Increase Productivity Fleet Operations.' },
          ]
        },
        {
          name: 'Dashboard Safety',
          type: 'desc',
          data: [
            { name: 'Information System that presents Driver Behavior during driving (Harsh Acceleration, Harsh Breaking, Harsh Cornering, Over Speed ​​& Fatigue) by displaying a monthly graphic display for driver behavior performance.' },
          ]
        },
        {
          name: 'Dashboard Realtime',
          type: 'desc',
          data: [
            { name: 'An information system that provides various kinds of information vehicle activity and can be adjusted according to needs.' },
          ]
        }
      ]
    }
  },
  id: {
    seal: {
      image: Product1PNG,
      name: 'E-seal ( Elektronic Seal )',
      description: 'Segel elektronik dari EasyGo ini merupakan solusi terlengkap untuk meningkatkan keamanan pengiriman barang. Untuk menjaga keamanan barang, E-Seal EasyGo menggunakan 2 electronic lock yaitu RFID Card Technology, dimana segel hanya bisa dibuka dengan kartu RFID, dan unlock by system.',
      description2: null,
      data: [
        {
          name: 'Informasi Fitur & Manfaat Eseal AT16',
          type: 'icon',
          data: [
            { icon: map, name: 'Realtime Tracking' },
            { icon: truck, name: 'Support to Delivery Order (DO) System Multi Asal Multi Tujuan' },
            { icon: battery, name: 'Long Life Battery 5.000 mAh' },
            { icon: phone, name: 'Electronic lock by : Proses Lock - Unlock by RFID Technology' },
            { icon: bell, name: 'Early warning system Email & Telegram ( Sosial Media )' },
            { icon: clock , name: 'Lock - String Alarm' },
            { icon: wifi, name: 'Connecting With 4G' },
            { icon: gear, name: 'Integration & Development System' },
            { icon: settings, name: 'Remote System Remote Upgrade by OTA' },
          ]
        }
      ]
    },
    tms: {
      image: Product4PNG,
      name: 'EasyGo TMS',
      description: 'Saatnya bisnis anda beralih ke digitalisasi, dengan menggunakan Teknologi IoT (Internet of Things ) berbasis GPS tracker (GPS Safety ) yang sesuai dengan regulasi pemerintah.',
      description2: null,
      data: [
        {
          name: 'Fleet Management',
          type: 'list',
          data: [
            { name: 'Maintenance Management Cost' },
            { name: 'Management ( Transaction ) Target' },
            { name: 'Per-Unit Route Management / Ritase' },
            { name: '(Utilisasi & Route Geofence)' },
            { name: 'Route Planner' },
            { name: 'User Access Management' },
            { name: 'Add On' }
          ]
        },
        {
          name: 'Order Management',
          type: 'list',
          data: [
            { name: 'Client Management' },
            { name: 'Pricing Management' },
            { name: 'Project Management' },
            { name: 'Job Order Management' },
            { name: 'Dashboard Order' },
            { name: 'Management' },
            { name: 'Planning Order &' },
            { name: 'Public Community' },
            { name: 'Target Order' },
            { name: 'Cost Management' },
            { name: 'Invoice Integrated' },
            { name: 'With Order' }
          ]
        },
        {
          name: 'Invoicing',
          type: 'list',
          data: [
            { name: 'Integrated Client ERP' }
          ]
        },
        {
          name: 'Driver/HR Management',
          type: 'list',
          data: [
            { name: 'DMS Report' },
            { name: 'E-POD' },
            { name: 'User/Hp Tracking' },
            { name: 'Attendance' },
            { name: 'Employee Schedule' },
            { name: 'Employee & Driver Activity' },
            { name: 'Cost Management' },
            { name: 'Report' },
            { name: 'KPI Driver' }
          ]
        },
        {
          name: 'Sales And Planning',
          type: 'list',
          data: [
            { name: 'TBD Application HR' },
            { name: 'Management' },
            { name: 'Sales Target' },
            { name: 'Daily Activity' },
            { name: 'KPI Sales' },
            { name: 'DMS Report' }
          ]
        },
        {
          name: 'Logistic Monitoring',
          type: 'list',
          data: [
            { name: 'Realtime Monitoring' },
            { name: 'Multi Platform' },
            { name: 'Alert Notification' },
            { name: 'Route Violation' },
            { name: 'Playback History' },
            { name: 'Geofence/PO/Checkpoint' },
            { name: 'Smart Dashboard' },
            { name: 'Analytical Report' }
          ]
        }
      ]
    },
    tracking: {
      image: Product3PNG,
      name: 'EasyGo Vehicle Tracking Devices',
      description: 'Dengan perangkat lunak (software) EasyGo, Anda dapat mengetahui lokasi kendaraan Anda dan dapat mengumpulkan data armada kendaraan Anda. Solusi manajemen armada kendaraan ini dapat menunjukkan secara tepat dan akurat perihal waktu yang dihabiskan, kapan, di mana, dan bagaimana bentuk pemanfaatan armada kendaraan Anda secara lebih optimal.',
      description2: 'Pelacakan kendaraan dari EasyGo mampu memberikan lokasi secara real-time dari setiap kendaraan dalam armada kendaraan Anda. Hal ini memungkinkan Anda untuk beradaptasi dengan cepat terhadap perkembangan baru sehingga mampu membuat keputusan yang lebih efektif dan efisien. Sistem pelacakan kendaraan EasyGo dapat memberikan informasi kepada Anda mengenai kendaraan mana yang terdekat untuk dapat diberikan tugas terbaru, serta memberi Anda akses instan ke kendaraan tersebut, serta informasi perilaku pengemudi dalam mengemudikan kendaraannya, seperti: jarak tempuh, batas kecepatan, bahkan cara pengereman pengemudi dalam memberhentikan kendaraannya. Pelacakan kendaraan ini dapat memberikan Anda informasi yang lebih baik, menghemat biaya sehingga membuat bisnis Anda lebih efisien.',
      data: []
    },
    dashboard: {
      image: Product2PNG,
      name: 'EasyGo Smart Dashboard',
      description: null,
      description2: null,
      data: [
        {
          name: 'Dashboard Executive Summary',
          type: 'desc',
          data: [
            { name: 'Sistem Informasi yang menampilkan nilai dari revenue, profit di bulan berjalan dengan ketentuan berapa kali armada melakukan delivery order / pengiriman. Dashboard Executive Summary juga menampilkan cost yg sudah dikeluarkan untuk armada tersebut. Memudahkan para Top Level Manajemen untuk melihat Kinerja Performance Bisnis yang dijalankan.' },
          ]
        },
        {
          name: 'Dashboard Delivery Order',
          type: 'desc',
          data: [
            { name: 'Sistem Informasi yang menyajikan aktivitas armada mengenai unit-unit mana saja yang sedang membawa DO dan yang tidak membawa DO dengan dibedakan berbagai macam jenis atau tipe armada yang dapat disesuaikan. Selain itu terdapat informasi mengenai Report atau Truck Utilisasi yang dapat membantu untuk mengoptimalkan Utilization Trip armada dan meningkatkan Produktivitas Operasional Armada.' },
          ]
        },
        {
          name: 'Dashboard Safety',
          type: 'desc',
          data: [
            { name: 'Sistem Informasi yang menyajikan Perilaku Pengemudi selama berkendara (Harsh Acceleration, Harsh Breaking , Harsh Cornering , Over Speed & Fatigue) dengan menampilkan tampilan grafik bulanan untuk performance perilaku pengemudi.' },
          ]
        },
        {
          name: 'Dashboard Realtime',
          type: 'desc',
          data: [
            { name: 'Sistem Informasi yang menyajikan berbagai macam Informasi aktivitas kendaraan dan dapat disesuaikan dengan kebutuhan.' },
          ]
        }
      ]
    }
  }
}