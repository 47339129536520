import { useEffect, useState } from 'react'
import { Card, Col, Divider, Row } from 'antd'
import { ClientDataSource } from './data.source'

const Recommended = () => {

  const [data, setData] = useState<any>()

  useEffect(() => {
    const initiate = async () => {
      const defaultLng = localStorage.getItem('@defaultLng')
      setData(defaultLng ? (defaultLng === 'en' ? ClientDataSource.en : ClientDataSource.id) : ClientDataSource.id)
    }

    initiate()
  }, [])

  return (
    <div className="recommended">
      <div className="title-wrapper">
        <h2 className="title-h1">{data && data.recommended}</h2>
      </div>
      <Row style={{alignItems: 'center', justifyContent: 'center'}}>
        {data && data.solutions.map((item: any, index: number) => (
          <Col lg={8} md={12} sm={24} className="card-col" key={index}>
            <Card title={item.title} hoverable>
              <div className="card-caption">{item.caption}</div>
              <h2 className="title-h2">{item.subtitle}</h2>
              <Divider />
              <div className="card-top-container">{item.description}</div>
              <Divider />
              <div className="card-container">
                <h3 className="title-h3">{item.footer}</h3>
                <ul>
                  {item.includes.map((item: any, index: any) => <li key={index}>{item}</li>)}
                </ul>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default Recommended