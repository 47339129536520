import * as React from "react"
import { SVGProps } from "react"

const YoutubeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={30}
    height={21}
    viewBox="0 0 30 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29.372 3.14a3.481 3.481 0 0 0-.957-1.588 3.812 3.812 0 0 0-1.673-.927C24.395.006 14.992.006 14.992.006c-3.92-.045-7.84.152-11.737.587a3.95 3.95 0 0 0-1.672.95 3.62 3.62 0 0 0-.972 1.595A36.358 36.358 0 0 0 0 10.005c-.015 2.3.189 4.6.61 6.866.171.599.505 1.145.968 1.586a3.87 3.87 0 0 0 1.676.929c2.379.617 11.736.617 11.736.617 3.926.045 7.85-.151 11.751-.587a3.813 3.813 0 0 0 1.673-.927c.456-.433.787-.982.956-1.588.431-2.263.64-4.563.626-6.867a33.216 33.216 0 0 0-.625-6.896v.001Zm-17.37 11.144V5.727l7.824 4.279-7.824 4.278Z"
      fill="#fff"
    />
  </svg>
)

export default YoutubeIcon
