import WPAPI from 'wpapi'

export const BASE = "https://new.easygo-gps.co.id/?rest_route=/wp/v2"
export const YARPP = "https://new.easygo-gps.co.id/?rest_route=/yarpp/v1"

export interface ProcessEnv {
  [key: string]: string | undefined
}

// export const ADMIN = "http://localhost:3000/api"
// export const ADMIN = "http://192.169.1.250:3000/api"
export const ADMIN = process.env["REACT_APP_API_URL"]

export const wp = new WPAPI({
  endpoint: 'https://new.easygo-gps.co.id/wp-json',
})


export const API_CONTENT = process.env["REACT_APP_API_CONTENT"]