import { useState, useEffect } from "react"
import { enquireScreen } from 'enquire-js'
import { HeaderNavDataSource } from "../components/Home/data.source"
import PreFooter from "../components/Home/PreFooter"
import Header from "../components/Home/Header"
import Footer from "../components/Home/Footer"
import DemoModal from "../components/Home/DemoModal"
import Resources from "../components/Home/Resources"
import { postAnalyticClick } from "../services/demo"
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import Joblist from '../components/Home/PrivacyPolice'
import { BannerDataSource } from '../components/Home/data.source'


let isThisMobile : boolean
enquireScreen((b : boolean) => {
  isThisMobile = b
})

const Privacy = () => {
  const [demoModalOpen, isDemoModalOpen] = useState(false)

  const [isMobile, setIsMobile] = useState(isThisMobile)
  const [show, isShown] = useState(false)

  const overPack = { className: 'home-page industry', playScale: 0.05 }
  

  useEffect(() => {
    enquireScreen((b : boolean) => {
      setIsMobile(!!b)
    })
    
    if (window.location.port) {
      setTimeout(() => {
        isShown(true)
      }, 500)
    } else {
      isShown(true)
    }
  }, [isMobile, show])

  const showDemoModal = async () => {
    isDemoModalOpen(!demoModalOpen)
    await postAnalyticClick('demo_clicks')
  }

  const children = [
    <Header
      dataSource={HeaderNavDataSource(showDemoModal)}
      isMobile={isMobile}
      key="Header"
    />,
    
    <Joblist
      isMobile={isMobile}
      key="Resources"
    />,
    <PreFooter
      isMobile={isMobile}
      key="Pre-Footer"
    />,
    <Footer
      dataSource={[]}
      isMobile={isMobile}
      key="Footer"
    />,
  ]

  return (
    <div
      className="templates-wrapper"
    >
      {show && children}
      <DemoModal modalOpen={demoModalOpen} isModalOpen={isDemoModalOpen} />
    </div>
  )
}

export default Privacy