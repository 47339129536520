import React from 'react'
import QueueAnim from 'rc-queue-anim'
import { Col, Row, Typography,Image } from 'antd'
import { Element } from 'react-scroll'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { getChildrenToRender, newChildrenToRender } from './utils'
import { IHomeComponent } from './types'

import Service1Image from '../../assets/images/service-0.png'
import Service2Image from '../../assets/images/service-1.png'
import Service3Image from '../../assets/images/service-2.png'
import { useTranslation } from 'react-i18next'

const { Link } = Typography

const Service : React.FC<IHomeComponent> = ({dataSource, isMobile, isNotHome, ...rest }) => {
  const {
    wrapper,
    titleWrapper,
    page,
    OverPack: overPackData,
    childWrapper,
  } = dataSource

  
  const {t} = useTranslation()
  console.log('titleWrapper',titleWrapper)
  console.log('page',page)
  console.log('OverPack',OverPack)
  console.log('childWrapper',childWrapper)
  return (
    <Element {...rest} {...wrapper}>
      <div {...page}>
        <div {...titleWrapper}>
          
          {titleWrapper.children.map(getChildrenToRender)}
          
        </div>
        <OverPack {...overPackData}>
          <QueueAnim
            type="bottom"
            key="block"
            leaveReverse
            componentProps={childWrapper}
          >
            {childWrapper.children.map((block: any, i: number) => {
              const { children: item, ...blockProps } = block
              return (
                <Col key={i.toString()} {...blockProps}>
                  <div style={{display:"flex",flexDirection:"row",alignItems:"center",height:"100%"}}>
                    {item.children.map((data:any,i:number)=>(
                      data.name === "image" ? (
                        <div key={i} style={{minWidth:"60px"}}><Image src={data.children} className={data.className} preview={false} /></div>
                      ):(
                        <div key={i}>
                            <div style={{display:"flex",flexDirection:'column',alignItems:'start',paddingLeft:20}}>
                            {data.name==='detail' ? (
                              data.children.map((detail:any,x:number)=>(
                                detail.name==='title' ? (
                                  <h2 key={x} style={{textAlign:'left'}}>{detail.children}</h2>
                                ):(
                                  detail.name==='desc' ? (
                                    <div style={{textAlign:'justify'}}>{detail.children}</div>
                                  ):(
                                    detail.name === 'items' ? (
                                      <ul style={{textAlign: 'justify',marginLeft:20}}>
                                        {detail.children.map((it:any,y:number)=>(
                                          <li key={y}>{it}</li>
                                        ))}  
                                      </ul>
                                      
                                    ):('')
                                  )
                                )
                              ))
                            ):("")}
                            </div>
                            
                        </div>
                      )  
                    ))}  
                    
                  </div>
                  {/* <div {...item}>

                    {item.children.map((data:any,i:number)=> (
                      <div key={i}>
                        {data.name==="image" ? (
                          <Image src={data.children} className={data.className} preview={false} />
                        ):(
                          data.name==="title" ? (
                            <div><b>{data.children}</b></div>
                          ):(
                            data.name==="desc" ? (
                              <div>{data.children}</div>
                            ):(
                              data.name==='items' ? (
                                <div style={{padding:10}}>
                                  {data.children.map((it:string,index:number)=>(
                                  <ul key={index} style={{textAlign:"left",fontSize:"12px"}}><li>{it}</li></ul>
                                ))}
                                </div>
                                
                              ):("")
                            )
                          )
                        )} 
                      </div>
                    ))}
                  </div> */}
                </Col>
              )
            })}
          </QueueAnim>
          
          {!isNotHome && (
            <Row className='row-content'>
              <Col md={24} lg={8}>
                
                <img src={Service1Image} alt={t('benefit.fleet')} width="100%" />
                <h2 className="caption-title">{t('benefit.fleet')}</h2>
                <div className="caption">{t('benefit.fleetDescription')}&nbsp;
                  <Link href={`/details/${t('benefit.fleetSlug')}`}>{t('benefit.more')}</Link>
                </div>
              </Col>
              <Col md={24} lg={8}>
                <img src={Service2Image} alt={t('benefit.business')} width="100%" />
                <h2 className="caption-title">{t('benefit.business')}</h2>
                <div className="caption">{t('benefit.businessDescription')}&nbsp;
                  <Link href={`/details/${t('benefit.businessSlug')}`}>{t('benefit.more')}</Link>
                </div>
              </Col>
              <Col md={24} lg={8}>
                <img src={Service3Image} alt={t('benefit.workflow')} width="100%" />
                <h2 className="caption-title">{t('benefit.workflow')}</h2>
                <div className="caption">{t('benefit.workflowDescription')}&nbsp;
                  <Link href={`/details/${t('benefit.workflowSlug')}`}>{t('benefit.more')}</Link>
                </div>
              </Col>
            </Row>
          )}
        </OverPack>
      </div>
    </Element>
  )
}

export default Service
