import * as React from "react"
import { SVGProps } from "react"

const FacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={21}
    height={21}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 .75H2A1.25 1.25 0 0 0 .75 2v20A1.25 1.25 0 0 0 2 23.25h10.769v-8.7H9.846v-3.406h2.923v-2.5c0-2.907 1.775-4.49 4.375-4.49.873-.003 1.748.042 2.619.133v3.026h-1.794c-1.41 0-1.685.672-1.685 1.656v2.168h3.371l-.438 3.407h-2.934v8.706H22A1.25 1.25 0 0 0 23.25 22V2A1.25 1.25 0 0 0 22 .75Z"
      fill="#fff"
    />
  </svg>
)

export default FacebookIcon
