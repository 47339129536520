import { useEffect, useState } from 'react'
import { enquireScreen } from 'enquire-js'
import { ServiceDataSource, BannerDataSource, HeaderNavDataSource } from '../components/Home/data.source'
import Header from '../components/Home/Header'

import '../components/Home/less/antMotionStyle.less'
import Banner from '../components/Home/Banner'
import Footer from '../components/Home/Footer'
import Service from '../components/Home/Service'
import PreFooter from '../components/Home/PreFooter'
import Client from '../components/Home/Client'
import DemoModal from '../components/Home/DemoModal'
import FAQ from '../components/Home/FAQ'
import { postAnalyticClick } from '../services/demo'

let isThisMobile : boolean
enquireScreen((b : boolean) => {
  isThisMobile = b
})

const FleetManagement = () => {
  const [isMobile, setIsMobile] = useState(isThisMobile)
  const [show, isShown] = useState(false)

  const [demoModalOpen, isDemoModalOpen] = useState(false)

  useEffect(() => {
    enquireScreen((b : boolean) => {
      setIsMobile(!!b)
    })
    
    if (window.location.port) {
      setTimeout(() => {
        isShown(true)
      }, 500)
    } else {
      isShown(true)
    }
  }, [isMobile, show])

  const showDemoModal = async () => {
    isDemoModalOpen(!demoModalOpen)
    await postAnalyticClick('demo_clicks')
  }

  const children = [
    <Header
      dataSource={HeaderNavDataSource(showDemoModal)}
      isMobile={isMobile}
      key="Header"
    />,
    <Banner
      dataSource={BannerDataSource(showDemoModal, "fleet-management")}
      isMobile={isMobile}
      key="Banner"
      isNotHome
    />,
    <Service
      dataSource={ServiceDataSource(true)}
      isMobile={isMobile}
      isNotHome
      key="Service"
    />,
    <FAQ
      isMobile={isMobile}
      key="faq"
    />,
    <Client
      isMobile={isMobile}
      isNotHome
      key="Client"
    />,
    <PreFooter
      isMobile={isMobile}
      key="Pre-Footer"
    />,
    <Footer
      dataSource={[]}
      isMobile={isMobile}
      key="Footer"
    />,
  ]

  return (
    <div
      className="templates-wrapper"
    >
      {show && children}

      <DemoModal modalOpen={demoModalOpen} isModalOpen={isDemoModalOpen} />
    </div>
  )
}

export default FleetManagement
