import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { IStaticComponent } from "./types"
import { Button, Col, Image, message, Modal, Radio, RadioChangeEvent, Row, Spin, Tag, Timeline, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SolutionDataSource } from './data.source'
import SolutionImage from './SolutionImage'
import Recommended from './Recommended'
import { postAdvisor } from '../../services/demo'
import DemoModal from './DemoModal'

const { Link } = Typography

const SolutionContent: React.FC<IStaticComponent> = ({isMobile, ...rest }) => {
  const { t } = useTranslation()

  const recRef = useRef<any>()

  const wrapper = { className: 'home-page-wrapper post-detail-wrapper' }
  const overPack = { className: 'home-page post-detail solution', playScale: 0.05 }

  const [data, setData] = useState<any>()
  const [activeStep, setActiveStep] = useState<number>(0)
  const [step1, setStep1] = useState<number>()
  const [step2, setStep2] = useState<string>()
  const [step3, setStep3] = useState<number[]>()
  const [demoModalOpen, isDemoModalOpen] = useState<boolean>(false)
  const [loading, isLoading] = useState<boolean>(false)
  
  useEffect(() => {
    const initiate = async () => {
      const defaultLng = localStorage.getItem('@defaultLng')
      setData(defaultLng ? (defaultLng === 'en' ? SolutionDataSource.en : SolutionDataSource.id) : SolutionDataSource.id)
    }

    initiate()
  }, [])

  const onStep2Change = ({ target: { value } }: RadioChangeEvent) => {
    setStep2(value)
  };

  const back = () => {
    setActiveStep(activeStep - 1)
  }

  const next = () => {
    switch (activeStep) {
      case 1:
        if (step2 === undefined) {
          message.error(t('error.fleet.size'))
        } else {
          setActiveStep(activeStep + 1)
        }
        break;
      default:
        if (step1 === undefined) {
          message.error(t('error.vehicle.type'))
        } else {
          setActiveStep(activeStep + 1)
        }
        break;
    }
  }

  const seePlan = () => {
    recRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  const finish = async () => {
    if (step3 === undefined) {
      message.error(t('error.individual.requirements'))
    } else {
      isDemoModalOpen(true)
    }
  }

  const submit = async (reg_id:string) => {
    isLoading(true)

    const vehicleType = data.vehicleTypes.find((_: any, index: number) => index === step1);
    const fleet_size = step2;
    const vehicle_type = vehicleType.title;
    const individualReqs = data.interests.filter((_: any, index: number) => step3?.includes(index)).map((item: any) => item.title);
    const individual_reqs = JSON.stringify(individualReqs);
    
    const params = {
      fleet_size: fleet_size,
      vehicle_type: vehicle_type,
      individual_reqs: individual_reqs,
      demo_request_id: parseInt(reg_id)
    };

    const resp = await postAdvisor(params)
    isLoading(false)
    if(resp){
      setActiveStep(3)
    }else{
      message.error('Oops something wrong, please try again')
    }
  }

  const getContentTitle = () => {
    switch (activeStep) {
      case 1:
        return <h3 className="title-h3">{data.step2Title}</h3>
      case 2:
        return <h3 className="title-h3">{data.step3Title}</h3>
      case 3:
        return <h3 className="title-h3">{data.step4Title}</h3>
      default:
        return <h3 className="title-h3">{data.step1Title}</h3>
    }
  }

  const getRecommendation = (item: any, index: number) => {
    if (index === 1) {
      return (
        <Row key={index} className="recommendations rec-right">
          <Col lg={14} md={24}>
            <h3 className="title-h3">{item.title}</h3>
            <p>{item.description}</p>
            <Button type="primary" onClick={() => seePlan()}>{t('common.pricing')}</Button>
          </Col>
          <Col lg={10} md={24} className="rec-col">
            <Image
              width="90%"
              alt={item.title}
              src={item.image}
            />
          </Col>
        </Row>
      )
    }

    return (
      <Row key={index} className="recommendations">
        <Col lg={10} md={24} className="rec-col">
          <Image
            width="90%"
            alt={item.title}
            src={item.image}
          />
        </Col>
        <Col lg={14} md={24}>
          <h3 className="title-h3">{item.title}</h3>
          <p>{item.description}</p>
          <Button type="primary" onClick={() => seePlan()}>{t('common.pricing')}</Button>
        </Col>
      </Row>
    )
  }

  const getContent = () => {
    switch (activeStep) {
      case 1:
        return (
          <Radio.Group options={data.step1Options} onChange={onStep2Change} value={step2} optionType="button" />
        )
      case 2:
        return (
          <Row wrap>
            {data.interests.map((item: any, index: number) => (
              <SolutionImage key={index} index={index} title={item.title} image={item.image} active={step3} setActive={setStep3} interest />
            ))}
          </Row>
        )
      case 3:
        return (
          <>
            {data.recommendations.map((item: any, index: number) => getRecommendation(item, index))}
          </>
        )
      default:
        return (
          <Row wrap>
            {data.vehicleTypes.map((item: any, index: number) => (
              <SolutionImage key={index} index={index} title={item.title} image={item.image} active={step1} setActive={setStep1} />
            ))}
          </Row>
        )
    }
  }

  const getBadge = (index: number) => {
    switch (index) {
      case 1:
        return step2 && <Tag color="#F96632">{step2}</Tag>
      case 2:
          return step3 && <Tag color="#F96632">{step3.length}</Tag>
      case 3:
          return <React.Fragment />
      default:
        return step1 ? <Image width={30} alt={data.vehicleTypes[step1].title} src={data.vehicleTypes[step1].image} /> : step1 === 0 && <Image width={30} alt={data.vehicleTypes[step1].title} src={data.vehicleTypes[step1].image} />
    }
  }

  return (
    <div {...rest} {...wrapper}>
      <OverPack {...overPack}>
        <Row>
          <Col lg={8} md={12} sm={24} className="container">
            <div className="title-wrapper">
              <h3 className="title-h3">{data && data.title}</h3>
              <div>{data && data.description}</div>
            </div>
            <div className="columns solution">
              {data && (
                <Timeline>
                  {data.steps.map((step: any, index: number) => (
                    <Timeline.Item key={index} color={activeStep === index ? "#F96632" : 'gray'}>
                      <span className="label">{step}</span>
                      {getBadge(index)}
                    </Timeline.Item>
                  ))}
                </Timeline>
              )}
            </div>
          </Col>
          <Col lg={16} md={12} sm={24} className="solution-container">
            {data && (
              <>
                {
                  !loading ? (
                    <div className="solution-right-content">
                      <div className="title-wrapper">
                        {getContentTitle()}
                      </div>
                      
                      <div className="s-content">
                        {getContent()}
                      </div>
                      {activeStep !== 3 && (
                        <>
                          <div className="s-content-button">
                            {activeStep !== 0 && <Button type={activeStep >= 3 ? "text" : "default"} onClick={() => back()}>{t('common.back')}</Button>}
                            {activeStep !== 2 && <Button type={activeStep >= 2 ? "default" : "primary"} onClick={() => next()}>{t('common.next')}</Button>}
                            {activeStep >= 2 && <Button type="primary" onClick={() => finish()}>{t('common.finish')}</Button>}
                          </div>
                          <div className="s-content-bottom">
                            <Link>{t('common.privacy')}</Link>
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="loading-container">
                      <Spin size="large" />
                    </div>
                  )
                }
              </>
            )}
          </Col>
        </Row>

        {activeStep === 3 && (
          <Row ref={recRef}>
            <Recommended/>
          </Row>
        )}
      </OverPack>

      <DemoModal
        modalOpen={demoModalOpen} 
        isModalOpen={isDemoModalOpen}
        onResponse={(id_reg) => submit(id_reg)}
      />
    </div>
  )
}

export default SolutionContent