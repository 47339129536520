import * as React from "react"
import { SVGProps } from "react"

const ArrowRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={13}
    height={13}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.367 8.433 8.512.748a.376.376 0 0 0-.246-.092H6.192a.187.187 0 0 0-.122.328l8.208 7.125H.562a.188.188 0 0 0-.187.188v1.406c0 .103.084.188.188.188h13.713l-8.208 7.125a.187.187 0 0 0 .122.328h2.144a.18.18 0 0 0 .122-.047l8.911-7.73a.752.752 0 0 0 0-1.134Z"
      fill="#fff"
    />
  </svg>
)

export default ArrowRightIcon
