import axios from "axios"
import { ADMIN } from "./init"

export const postDemo = async (values: any) => {
  const response = await axios.post(`${ADMIN}/demo-requests/add`, JSON.stringify(values), {
    headers: {
      "Content-Type": 'application/json',
      "Accept": "application/json",
    },
    
  })

  if (response.status === 200) {
    if (response.data) {
      return response.data
    } else {
      return null
    }
  }

  return null
}

export const postSavings = async (values: any) => {
  const response = await axios.post(`${ADMIN}/savings-calculator/add`, JSON.stringify(values), {
    headers: {
      "Content-Type": 'application/json',
      "Accept": "application/json",
    },
    
  })

  if (response.status === 200) {
    if (response.data) {
      return response
    } else {
      return null
    }
  }

  return null
}

export const postAdvisor = async (values: any) => {
  const response = await axios.post(`${ADMIN}/solution-advisors/add`, JSON.stringify(values), {
    headers: {
      "Content-Type": 'application/json',
      "Accept": "application/json",
    },
    
  })

  if (response.status === 200) {
    if (response.data) {
      return response
    } else {
      return null
    }
  }

  return null
}

export const postAnalyticClick = async (property_name: string) => {
  const values = {
    property_name: property_name,
  }
  const response = await axios.post(`${ADMIN}/site-analytics/update`, JSON.stringify(values), {
    headers: {
      "Content-Type": 'application/json',
      "Accept": "application/json",
    },
    
  })

  if (response.status === 200) {
    if (response.data) {
      return response.data
    } else {
      return null
    }
  }

  return null
}
