
import React from 'react'
import { Button } from 'antd'
import { Divider } from 'rc-menu'

export const isImg = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?/

export const isImportedImg = /[/.](gif|jpg|jpeg|tiff|png|svg)$/i

export const getChildrenToRender = (item: any, i: number) => {
  let tag = item.name.indexOf('title') === 0 ? 'h2' : 'div'
  tag = item.href ? 'a' : tag
  let children = typeof item.children === 'string' && item.children.match(isImportedImg)
    ? React.createElement('img', { src: item.children, alt: 'img' })
    : item.children
  if (item.name.indexOf('button') === 0 && typeof item.children === 'object') {
    children = React.createElement(Button, {
      ...item.children
    })
  }
  return React.createElement(tag, { key: i.toString(), ...item }, children)
}

export const newChildrenToRender = (item: any, i:number) => {
  
}