import { Button, Form, Input, message, Modal, Select } from 'antd'
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom"
import { postDemo } from '../../services/demo';
import { getAttachment } from '../../services/posts';

type Props = {
  modalOpen: boolean;
  isModalOpen: any;
  attachment?: number;
  onResponse?: (arg: string) => void;
}

const DemoModal: React.FC<Props> = ({modalOpen, isModalOpen, attachment, onResponse}) => {
  const {t} = useTranslation()
  const handleOk = () => {
    isModalOpen(false)
  }

  const handleCancel = () => {
    isModalOpen(false)
  }

  const onFinish = async (values: any) => {
    const data = {
      first_name: values.firstName,
      last_name: values.lastName,
      company_name: values.companyName,
      email: values.email,
      contact: values.contactNumber,
      position: values.position,
      number_of_fleets: values.fleetSize,
    };

    const resp = await postDemo(data).then();
    if(resp.status){
      if(onResponse){
        onResponse(resp.data.demo_request_id)
      }
    }else{
      message.error('Oops something wrong, please try again')
    }

    if (attachment) {
      const att = await getAttachment(attachment)

      const link = document.createElement("a");
      link.href = att.source_url;
      link.download = att.title.rendered + ".pdf";
      link.target = "_blank";
      link.click();
    }

    handleCancel();
  }
  
  return (
    <Modal
      title={(
        <div className="modal-title-header">
          <div className="ant-modal-title">{attachment ? t('modal.ebookTitle') : t('modal.title')}</div>
          <div className="ant-modal-subtitle">{attachment ? t('modal.ebookCaption') : t('modal.caption')}</div>
        </div>
      )}
      className="no-footer"
      open={modalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        name="demo-form"
        className="demo-form"
        initialValues={{}}
        onFinish={onFinish}
      >
        <Form.Item
          name="firstName"
          rules={[{ required: true, message: `${t('modal.error.firstName')}` }]}
        >
          <Input placeholder={t('modal.firstName')} />
        </Form.Item>
        <Form.Item
          name="lastName"
          rules={[{ required: true, message: `${t('modal.error.lastName')}` }]}
        >
          <Input placeholder={t('modal.lastName')} />
        </Form.Item>
        <Form.Item
          name="companyName"
          rules={[{ required: true, message: `${t('modal.error.companyName')}` }]}
        >
          <Input placeholder={t('modal.companyName')} />
        </Form.Item>
        <Form.Item
          name="position"
          rules={[{ required: true, message: `${t('modal.error.position')}` }]}
        >
          <Input placeholder={t('modal.position')} />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[{ required: true, message: `${t('modal.error.email')}` }]}
        >
          <Input placeholder={t('modal.email')} type="email" />
        </Form.Item>
        <Form.Item
          name="contactNumber"
          rules={[{ required: true, message: `${t('modal.error.contactNumber')}` }]}
        >
          <Input placeholder={t('modal.contactNumber')} />
        </Form.Item>
        <Form.Item
          name="fleetSize"
          rules={[{ required: true, message: `${t('modal.error.fleetSize')}` }]}
        >
          <Input placeholder={t('modal.fleetSize')} />
        </Form.Item>
        <Form.Item className="form-controls">
          <Button type="primary" htmlType="submit" className="demo-form-button">
            {t('modal.button')}
          </Button>
          <div className="controls-caption">
            {t('modal.footer')} {t('modal.seeour')} <Link to="/">{t('common.privacy')}</Link> {t('modal.more')}
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default DemoModal