import TweenOne from 'rc-tween-one'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { IStaticComponent } from "./types"
import { Col, Row, Typography, Button, Form, Input, Upload, Select, Space } from 'antd'
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import Logo from '../../assets/images/Logo_white.png' 
import Barcode from '../../assets/images/barcode.png'
import YoutubeIcon from '../Icons/Youtube'
import InstagramIcon from '../Icons/Instagram'
import FacebookIcon from '../Icons/Facebook'
import LinkedInIcon from '../Icons/LinkedIn'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';



const qs = require('qs');

const { Text, Link } = Typography

const PreFooter: React.FC<IStaticComponent> = ({isMobile, ...rest }) => {
    const {t} = useTranslation()
    const wrapper = { className: 'home-page-wrapper job-wrapper' }
    const overPack = { className: 'home-page pre-footer', playScale: 0.05 }
    const [jobs,setJobs] = useState<any>([])
    const [upload_link, setuploadLink] = useState<string>('');
    const [uploadFile, setUploadFile] = useState<any>()
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [form] = Form.useForm();
    const [commitSuccess,setCommitSuccess] = useState<any>(false)


    useEffect(()=>{
        init()
    },[])

    const init = async () => {
        const url:any = process.env.REACT_APP_API_CONTENT+'/jobs'
        const params = {
            project_id: process.env.REACT_APP_PROJECT_ID,
            rows: 1000,
            page: 1
        }
        const data = await axios.get(url+'?'+qs.stringify(params))
        
        console.log('data',data.data.data.data)
        if(data.data.status){
            setJobs(data.data.data.data)
        }
    }

    const onFinish = async (values: any) => {
        console.log('Success:', values);

       
        if(!uploadFile){
            return false
        }else{
            // upload to CDN
            const cdn_user:any = process.env.REACT_APP_CDN_USERNAME
            const cdn_pass:any = process.env.REACT_APP_CDN_PASSWORD
            console.log('file',uploadFile)
            const formData = new FormData();
            formData.append('file', uploadFile);
            formData.append('email',cdn_user)
            formData.append('password',cdn_pass)
     
     
            // Kirim file ke backend menggunakan Axios
            const url:any = process.env.REACT_APP_CDN_URL

            try {
                const res_upload:any = await axios.post(url, formData)
                console.log('res_upload',res_upload)
                if(res_upload.data.status){
                    console.log('url',res_upload.data.url)
                    const params = {
                        project_id : process.env.REACT_APP_PROJECT_ID,
                        job_id: values.job_id,
                        email: values.email,
                        name: values.name,
                        phone: values.phone,
                        document: res_upload.data.data.url 
                    }

                    const config = {
                        headers: {
                            "Content-Type": 'application/json',
                            "Accept": "application/json",
                        },
                    }

                    try {
                        const post_seeker:any = await axios.post(process.env.REACT_APP_API_CONTENT+'/jobs/seeker',JSON.stringify(params),{
                            headers: {
                                "Content-Type": 'application/json',
                                "Accept": "application/json",
                            }
                        })

                        console.log('post_seeker',post_seeker)
                        if(post_seeker.data.status){
                            form.resetFields();
                            if (fileInputRef.current) {
                                fileInputRef.current.value = '';
                            }
                            // sweetalert
                            setCommitSuccess(true)
                        }
                    } catch (error) {
                        return false
                    }
                }

            } catch (error) {
                return false
            }
            
        }
        // write
    };
      
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const handleUpload = (file:any) => {
        const cdn_user:any = process.env.REACT_APP_CDN_USERNAME
        const cdn_pass:any = process.env.REACT_APP_CDN_PASSWORD
        console.log('file',file)
        const formData = new FormData();
        formData.append('file', file);
        formData.append('email',cdn_user)
        formData.append('password',cdn_pass)


        // Kirim file ke backend menggunakan Axios
        const url:any = process.env.REACT_APP_CDN_URL
        axios.post(url, formData)
            .then(response => {
            // Tangani respons dari backend
            // Misalnya, tampilkan pesan sukses atau error
        })
        .catch(error => {
            // Tangani kesalahan yang terjadi saat mengirim file
        });
    }

    const actionUpload = (st:string) => {
        console.log('data&&&&&', st)

    }

    const handleAction = (res:any) => {
        console.log('res',res)
    }

    

    const handleFileUpload = (file:any) =>{
        setUploadFile(file.target.files[0])
        
    }

    const DeleteUploadFile = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }

        setUploadFile(null)
    }
    
    return (
        <div {...rest} {...wrapper}>
            <OverPack {...overPack}>
                <TweenOne
                animation={{ x: '+=30', opacity: 0, type: 'from' }}
                key="card"
                className="footer-copyright"
                >
                    
                    <div className="title-wrapper">
                        <h2>Privacy Policy</h2>

                    </div>
                    
                    <h3>
                    This Privacy Policy is intended to help you understand what data we collect and why we need it. This is an important thing that we hope you can take the time to read carefully so that you can understand it as simply as possible.<br></br><br></br>
                    
                    Collecting Device Information<br></br>

                    TMS Easygo application uses hardware to collect information such as (vehicle model, cellular network information and location information). This information is needed to improve how you can monitor your vehicle unit in real time.<br></br><br></br>

                    Use of Information That May Be Collected<br></br>

                    TMS EasyGo uses privacy information for the purpose of verifying your ownership of an account in this application. This information is validated so that you can monitor your vehicle units, use or manage vehicles in the application services that we have.<br></br><br></br>

                    Requires Location Information<br></br>

                    TMS EasyGo application requires a GPS device installed in the vehicle to obtain the position of your vehicle units. Apart from that, this application also requires GPS access on your smartphone which is used to obtain the location of the user of this application.<br></br><br></br>

                    Requires Storage Access<br></br>

                    TMS EasyGo application requires access to the storage on your device so that the features in this application can work properly.<br></br><br></br>

                    Security Information<br></br>

                    We have implemented administrative and technical safeguards that are believed to be sufficient to protect the confidentiality of your data, and we use infrastructure stored in a cloud architecture that has been certified for security.<br></br><br></br>

                    How to Contact Us<br></br>

                    If you have other questions or complaints, you can contact us via email or telephone via the following contacts:<br></br>

                    Email: cseasygo@easygo-gps.co.id<br></br>

                    Customer Service: 0815-8806-116<br></br>

                    Tel: (021) 69830038

                    </h3>
                    
                </TweenOne>

                
            </OverPack>
        </div>
    )
}
    
export default PreFooter