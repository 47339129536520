import * as React from "react"
import { SVGProps } from "react"

const GlobeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M0 12.5a12 12 0 1 1 24 0 12 12 0 0 1-24 0ZM11.25 2.116c-1.005.305-2.002 1.23-2.83 2.782A11.955 11.955 0 0 0 7.717 6.5h3.532V2.115ZM6.135 6.5a13.9 13.9 0 0 1 .96-2.309c.26-.49.559-.958.896-1.399A10.538 10.538 0 0 0 3.381 6.5h2.753Zm-.873 5.25c.045-1.316.207-2.577.468-3.75H2.511a10.437 10.437 0 0 0-.984 3.75h3.735ZM7.271 8a18.75 18.75 0 0 0-.508 3.75h4.487V8H7.27Zm5.479 0v3.75h4.485A18.743 18.743 0 0 0 16.73 8h-3.98Zm-5.985 5.25c.042 1.264.211 2.52.506 3.75h3.979v-3.75H6.765Zm5.985 0V17h3.98a18.68 18.68 0 0 0 .506-3.75H12.75ZM7.718 18.5c.207.579.442 1.116.701 1.602.829 1.552 1.827 2.475 2.831 2.782V18.5H7.718Zm.272 3.708c-.336-.441-.636-.91-.895-1.4-.389-.739-.71-1.511-.96-2.308H3.383a10.536 10.536 0 0 0 4.607 3.708ZM5.73 17a20.478 20.478 0 0 1-.468-3.75H1.527c.093 1.335.437 2.6.984 3.75H5.73Zm10.28 5.208a10.536 10.536 0 0 0 4.607-3.708h-2.752c-.25.797-.571 1.57-.96 2.308-.26.491-.559.96-.896 1.4ZM12.75 18.5v4.384c1.005-.306 2.002-1.23 2.83-2.782.26-.486.496-1.023.702-1.602H12.75Zm5.52-1.5h3.219a10.39 10.39 0 0 0 .984-3.75h-3.735A20.475 20.475 0 0 1 18.27 17Zm4.203-5.25A10.44 10.44 0 0 0 21.489 8H18.27c.261 1.173.423 2.434.468 3.75h3.735Zm-5.568-7.559c.37.697.693 1.47.96 2.309h2.753a10.536 10.536 0 0 0-4.608-3.708c.326.426.627.897.895 1.4ZM16.282 6.5c-.194-.55-.429-1.086-.701-1.602-.829-1.552-1.826-2.475-2.831-2.782V6.5h3.532Z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(0 .5)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default GlobeIcon
