import * as React from "react"
import { SVGProps } from "react"

const NotFoundIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ? props.width : 461}
    height={props.height ? props.height : 155}
    viewBox="0 0 461 155"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={196.504}
      y={39.609}
      width={21.967}
      height={20.531}
      rx={3.5}
      fill="#F96632"
      stroke="#F96632"
    />
    <rect
      x={240.285}
      y={39.609}
      width={21.967}
      height={20.531}
      rx={3.5}
      fill="#F96632"
      stroke="#F96632"
    />
    <rect
      x={251.768}
      y={96.305}
      width={21.967}
      height={20.531}
      rx={3.5}
      fill="#F96632"
      stroke="#F96632"
    />
    <rect
      x={185.021}
      y={96.305}
      width={21.967}
      height={20.531}
      rx={3.5}
      fill="#F96632"
      stroke="#F96632"
    />
    <rect
      x={200.811}
      y={80.875}
      width={57.493}
      height={20.531}
      rx={3.5}
      fill="#F96632"
      stroke="#F96632"
    />
    <mask id="a" fill="#fff">
      <rect x={138.604} y={0.852} width={183.671} height={153.91} rx={8} />
    </mask>
    <rect
      x={138.604}
      y={0.852}
      width={183.671}
      height={153.91}
      rx={8}
      stroke="#F96632"
      strokeWidth={40}
      mask="url(#a)"
    />
    <rect width={31.462} height={74.829} rx={8} fill="#F96632" />
    <rect width={31.462} height={74.829} rx={8} stroke="#F96632" />
    <rect
      x={73.128}
      y={2.555}
      width={31.462}
      height={148.808}
      rx={8}
      fill="#F96632"
    />
    <rect
      x={73.128}
      y={2.555}
      width={31.462}
      height={148.808}
      rx={8}
      stroke="#F96632"
    />
    <rect
      x={16.156}
      y={87.578}
      width={31.462}
      height={74.829}
      rx={8}
      transform="rotate(-90 16.156 87.578)"
      fill="#F96632"
    />
    <rect
      x={16.156}
      y={87.578}
      width={31.462}
      height={74.829}
      rx={8}
      transform="rotate(-90 16.156 87.578)"
      stroke="#F96632"
    />
    <rect x={356.288} width={31.462} height={74.829} rx={8} fill="#F96632" />
    <rect x={356.288} width={31.462} height={74.829} rx={8} stroke="#F96632" />
    <rect
      x={429.416}
      y={2.555}
      width={31.462}
      height={148.808}
      rx={8}
      fill="#F96632"
    />
    <rect
      x={429.416}
      y={2.555}
      width={31.462}
      height={148.808}
      rx={8}
      stroke="#F96632"
    />
    <rect
      x={372.444}
      y={87.578}
      width={31.462}
      height={74.829}
      rx={8}
      transform="rotate(-90 372.444 87.578)"
      fill="#F96632"
    />
    <rect
      x={372.444}
      y={87.578}
      width={31.462}
      height={74.829}
      rx={8}
      transform="rotate(-90 372.444 87.578)"
      stroke="#F96632"
    />
  </svg>
)

export default NotFoundIcon
