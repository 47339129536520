import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import { IHomeComponent } from "./types"
import { getChildrenToRender } from "./utils"
import { Player } from "video-react"
import { Button, Modal, Carousel } from 'antd'
import { PlayCircleFilled } from '@ant-design/icons'
import { useEffect, useRef, useState } from 'react'
import axios from 'axios'

import BannerVideo from "../../assets/videos/EASYGO.mp4"
import CloseIcon from '../Icons/Close'

const qs = require('qs');

interface IBanner {
    isMobile: boolean
    showModal?: any;
    isNotHome?: boolean
    bannerType?: string
    componentRef?: any
}

const BannerCarrer : React.FC<IBanner> = ({ isMobile, isNotHome, ...rest }) => {
    const vRef = useRef<any>(null)
    const [modalOpen, isModalOpen] = useState(isNotHome?false:true)
    const [jobs,setJobs] = useState<any>([])

    useEffect(()=>{
        init()
    },[])

    const init = async () => {
        const url:any = process.env.REACT_APP_API_CONTENT+'/jobs'
        const params = {
            project_id: process.env.REACT_APP_PROJECT_ID,
            rows: 1000,
            page: 1
        }
        const data = await axios.get(url+'?'+qs.stringify(params))
        
        if(data.data.status){
            // console.log('ini banner',data.data.data)
            setJobs(data.data.data.data)
        }
    }

    const animType = {
        queue: 'bottom',
        one: {
            y: '+=30',
            opacity: 0,
            type: 'from',
            ease: 'easeOutQuad',
        },
    }
    
    const openModal = () => {
        isModalOpen(true)
        setTimeout(() => {
            vRef.current?.play()
        }, 1000);
    }
    
    const cancelModal = () => {
        vRef.current?.pause()
        isModalOpen(false)
    }
    
    return (
            <div style={{marginTop:"64px"}}>
                <Carousel autoplay={true} dots={false}>
                    <div className='job-banner'>
                        <img style={{maxWidth: "100%"}} src={process.env.REACT_APP_DEFAULT_JOB_BANNER} alt="" />
                    </div>
                    {jobs.map((job:any,index:number)=>(
                        <div key={index}>
                            <img style={{maxWidth: "100%"}} src={job.banner} alt="" />
                        </div>
                    ))}
                    
                </Carousel>
        
            </div>
        )
    }
    
    export default BannerCarrer
    