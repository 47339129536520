import TweenOne from 'rc-tween-one'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { IStaticComponent } from "./types"
import { Button, Card, Col, Empty, Row, Spin, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { paginatePosts } from '../../services/posts'
import { useTranslation } from 'react-i18next'
import { ClientDataSource } from './data.source'
import moment from 'moment'
import ArrowRightIcon from '../Icons/ArrowRight'
import { postAnalyticClick } from '../../services/demo'

const { Text, Link } = Typography

const { Meta } = Card

const Resources: React.FC<IStaticComponent> = ({isMobile, ...rest }) => {
  const { t } = useTranslation()

  const wrapper = { className: 'home-page-wrapper post-detail-wrapper' }
  const overPack = { className: 'home-page post-detail', playScale: 0.05 }

  const [data, setData] = useState<any>()
  const [loading, isLoading] = useState<boolean>(false)
  const [articles, setArticles] = useState<any[]>([])
  const [ebooks, setEbooks] = useState<any[]>([])
  const [videos, setVideos] = useState<any[]>([])

  useEffect(() => {
    const initiate = async () => {
      isLoading(true)
      const defaultLng = localStorage.getItem('@defaultLng')
      const language = defaultLng ? defaultLng : 'id'
      setData(defaultLng ? (defaultLng === 'en' ? ClientDataSource.en : ClientDataSource.id) : ClientDataSource.id)
      
      const videos = await paginatePosts("345,343", 1, 2)
      setVideos(videos)

      const ebooks = await paginatePosts("347,349", 1, 2)
      setEbooks(ebooks)

      const category = language === 'id' ? 71 : 141
      const posts = await paginatePosts(category, 1, 2)
      setArticles(posts)

      isLoading(false)
    }

    initiate()
  }, [])

  const CardCover = (slug: string, article: any) => {
    if (slug === 'video') {
      const video_link = article.acf.video_link
      const ytCode = video_link.substring(video_link.lastIndexOf('/') + 1)
      
      return (
        <iframe
          height="200"
          src={`https://www.youtube.com/embed/${ytCode}`}
          title={article.title.rendered}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          className="card-iframe"
          allowFullScreen></iframe>
      )
    }
    
    return (
      <img alt={article.title.rendered} src={article._embedded['wp:featuredmedia'][0]['media_details']['sizes']['medium']['source_url']} />
    )
  }

  const startSolutionAdvisor = async () => {
    await postAnalyticClick("advisor_clicks")
    window.location.href = "/solution"
  }

  return (
    <div {...rest} {...wrapper}>
      <OverPack {...overPack}>
        <Row>
          <Col lg={8} md={24} sm={24} xs={24} className="container">
            <div className="title-wrapper">
              <h3 className="title-h3">{t('common.content.type')}</h3>
            </div>
            <div className="columns">
              <Link href="/resources/article" className={window.location.href.indexOf("article") > -1 ? "active" : ""}>{t('article')}</Link>
              <Link href="/resources/e-book" className={window.location.href.indexOf("e-book") > -1 ? "active" : ""}>{t('ebook')}</Link>
              <Link href="/resources/video" className={window.location.href.indexOf("video") > -1 ? "active" : ""}>{t('video')}</Link>
            </div>
            <div className="center-columns">
              <span>{data && data.footerDescription[1]}</span>
              <Button type="primary" onClick={() => startSolutionAdvisor()}>
                {t('common.advise')}
              </Button>
            </div>
          </Col>
          <Col lg={16} md={24} sm={24} xs={24} className="container">
            <Row className="right-container">
              <Col md={12}>
                <h3 className="title-h3 bold">{t('video')}</h3>
              </Col>
              <Col md={12}>
                <h3 className="title-h3 bold right">{t('common.view.all')}</h3>
              </Col>
            </Row>

            {!loading ? (
              <TweenOne
                animation={{ x: '+=30', opacity: 0, type: 'from' }}
                key="post-detail"
                className="post-detail"
              >
                {videos.length > 0 ? (
                  <Row wrap>
                    {videos.map((item: any, index: number) => (
                      <div className="col-12" key={index}>
                        <Card
                          className="article-card"
                          hoverable
                          cover={CardCover("video", item)}
                          onClick={() => window.location.href = `/details/${item.slug}`}
                        >
                          <Row>
                            <Col span={12}>
                              <Text type="secondary">{moment(item.date).format('MMM DD, YYYY')}</Text>
                            </Col>
                            <Col span={12} className="right">
                              <Text type="secondary">{t('common.by')} {item._embedded['author'][0]['name']}</Text>
                            </Col>
                          </Row>
                          <Meta
                            title={(<Text strong >{item.title.rendered}</Text>)}
                          />
                          <div className="right-button">
                            <ArrowRightIcon />
                          </div>
                        </Card>
                      </div>
                    ))}
                  </Row>
                ) : (
                  <Empty />
                )}
              </TweenOne>
            ) : (
              <div className="loading-container">
                <Spin size="large" />
              </div>
            )}

            <Row className="right-container">
              <Col md={12}>
                <h3 className="title-h3 bold">{t('article')}</h3>
              </Col>
              <Col md={12}>
                <h3 className="title-h3 bold right">{t('common.view.all')}</h3>
              </Col>
            </Row>

            {!loading ? (
              <TweenOne
                animation={{ x: '+=30', opacity: 0, type: 'from' }}
                key="post-detail"
                className="post-detail"
              >
                {articles.length > 0 ? (
                  <Row wrap>
                    {articles.map((item: any, index: number) => (
                      <div className="col-12" key={index}>
                        <Card
                          className="article-card"
                          hoverable
                          cover={CardCover("article", item)}
                          onClick={() => window.location.href = `/details/${item.slug}`}
                        >
                          <Row>
                            <Col span={12}>
                              <Text type="secondary">{moment(item.date).format('MMM DD, YYYY')}</Text>
                            </Col>
                            <Col span={12} className="right">
                              <Text type="secondary">{t('common.by')} {item._embedded['author'][0]['name']}</Text>
                            </Col>
                          </Row>
                          <Meta
                            title={(<Text strong >{item.title.rendered}</Text>)}
                          />
                          <div className="right-button">
                            <ArrowRightIcon />
                          </div>
                        </Card>
                      </div>
                    ))}
                  </Row>
                ) : (
                  <Empty />
                )}
              </TweenOne>
            ) : (
              <div className="loading-container">
                <Spin size="large" />
              </div>
            )}

            <Row className="right-container">
              <Col md={12}>
                <h3 className="title-h3 bold">{t('ebook')}</h3>
              </Col>
              <Col md={12}>
                <h3 className="title-h3 bold right">{t('common.view.all')}</h3>
              </Col>
            </Row>

            {!loading ? (
              <TweenOne
                animation={{ x: '+=30', opacity: 0, type: 'from' }}
                key="post-detail"
                className="post-detail"
              >
                {ebooks.length > 0 ? (
                  <Row wrap>
                    {ebooks.map((item: any, index: number) => (
                      <div className="col-12" key={index}>
                        <Card
                          className="article-card"
                          hoverable
                          cover={CardCover("article", item)}
                          onClick={() => window.location.href = `/details/${item.slug}`}
                        >
                          <Row>
                            <Col span={12}>
                              <Text type="secondary">{moment(item.date).format('MMM DD, YYYY')}</Text>
                            </Col>
                            <Col span={12} className="right">
                              <Text type="secondary">{t('common.by')} {item._embedded['author'][0]['name']}</Text>
                            </Col>
                          </Row>
                          <Meta
                            title={(<Text strong >{item.title.rendered}</Text>)}
                          />
                          <div className="right-button">
                            <ArrowRightIcon />
                          </div>
                        </Card>
                      </div>
                    ))}
                  </Row>
                ) : (
                  <Empty />
                )}
              </TweenOne>
            ) : (
              <div className="loading-container">
                <Spin size="large" />
              </div>
            )}
          </Col>
        </Row>
      </OverPack>
    </div>
  )
}

export default Resources