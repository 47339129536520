import TweenOne from 'rc-tween-one'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { IStaticComponent } from "./types"
import { Button, Col, Image, List, Row, Spin, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { getContentBySlug, getRelatedContent } from '../../services/posts'
import { useTranslation } from 'react-i18next'
import { Link as RLink } from 'react-router-dom'
import { ClientDataSource } from './data.source'
import { postAnalyticClick } from '../../services/demo'

const { Text, Link, Paragraph } = Typography

const PostDetail: React.FC<IStaticComponent> = ({isMobile, slug, downloadEbook, ...rest }) => {
  const { t } = useTranslation()

  const wrapper = { className: 'home-page-wrapper post-detail-wrapper' }
  const overPack = { className: 'home-page post-detail posts', playScale: 0.05 }

  const [data, setData] = useState<any>()
  const [article, setArticle] = useState<any>()
  const [related, setRelated] = useState<any[]>([])

  useEffect(() => {
    const initiate = async () => {
      const defaultLng = localStorage.getItem('@defaultLng')
      setData(defaultLng ? (defaultLng === 'en' ? ClientDataSource.en : ClientDataSource.id) : ClientDataSource.id)
      if (slug) {
        const language_id = defaultLng === 'en' ? 1 : 2

        const post = await getContentBySlug(slug, language_id)
        setArticle(post)

        const relatedPosts = await getRelatedContent(post.content.content_type_id, language_id)
        setRelated(relatedPosts.data)
      }
    }

    initiate()
  }, [slug])

  const startSolutionAdvisor = async () => {
    await postAnalyticClick("advisor_clicks")
    window.location.href = "/solution"
  }

  const getRelatedArticle = (item: any) => {
    return (
      <List.Item>
        <List.Item.Meta
          avatar={
            item.content.content_images[0] &&
            <Image
              width={120}
              alt={item.content.content_languages[0].title}
              src={item.content.content_images[0].url}
            />
          }
          title={<RLink to={`/details/${item.content.slug}`}><Paragraph ellipsis={{ rows: 2 }}>{item.content.content_languages[0].title}</Paragraph></RLink>}
          description={<Text>{t('common.by')} {item.content['author']['name']}</Text>}
        />
      </List.Item>
    )
  }
  
  return (
    <div {...rest} {...wrapper}>
      <OverPack {...overPack}>
        <Row>
          <Col lg={16} md={24} sm={24} xs={24} className="container">
            {article ? (
              <>
                <div className="title-wrapper">
                  <h3 className="title-h3">{article.content.content_languages[0].title}</h3>
                  {
                    article.content.content_type_id !== 4 &&
                    <Text>{t('common.by')} {article.content['author']['name']}</Text>
                  }
                </div>
                <TweenOne
                  animation={{ x: '+=30', opacity: 0, type: 'from' }}
                  key="post-detail"
                  className="post-detail"
                >
                  {article.content.content_images[0] && (
                    <Image
                      width={"100%"}
                      src={article.content.content_images[0]?.url}
                    />
                  )}
                  
                  <div className="post-content" dangerouslySetInnerHTML={{ __html: article.content.content_languages[0].full_content}} />

                  {article.content.content_files[0] && (
                    <Button type="primary" onClick={() => downloadEbook(article.content.content_files[0]?.url)}>
                      {t('common.download')} {t('ebook')}
                    </Button>
                  )}
                </TweenOne>
              </>
            ) : (
              <div className="loading-container">
                <Spin size="large" />
              </div>
            )}
          </Col>
          <Col lg={8} md={24} sm={24} xs={24} className="container">
            <div className="title-wrapper">
              <h3 className="title-h3">{t('blog.related')}</h3>
            </div>
            <div className="columns">
              {related && (
                <List
                  itemLayout="horizontal"
                  dataSource={related}
                  renderItem={item => getRelatedArticle(item)}
                />
              )}
            </div>
            <div className="title-wrapper">
              <h3 className="title-h3">{t('common.content.type')}</h3>
            </div>
            <div className="columns">
              <Link href="/resources/news">{t('news')}</Link>
              <Link href="/resources/article">{t('article')}</Link>
              <Link href="/resources/e-book">{t('ebook')}</Link>
              <Link href="/resources/video">{t('video')}</Link>
            </div>
            <div className="center-columns">
              {data && <span dangerouslySetInnerHTML={{ __html: data.footerDescription[1] }} />}
              <Button type="primary" onClick={() => startSolutionAdvisor()}>
                {t('common.advise')}
              </Button>
            </div>
          </Col>
        </Row>
      </OverPack>
    </div>
  )
}

export default PostDetail