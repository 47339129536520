import TweenOne from 'rc-tween-one'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { IStaticComponent } from "./types"
import { Collapse } from 'antd'
import { FAQDataSource } from './data.source'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const { Panel } = Collapse

const FAQ: React.FC<IStaticComponent> = ({isMobile, ...rest }) => {
  const {t} = useTranslation()
  const wrapper = { className: 'home-page-wrapper faq-wrapper' }
  const overPack = { className: 'home-page faq', playScale: 0.05 }
  
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    const initiate = async () => {
      const defaultLng = localStorage.getItem('@defaultLng')
      setData(defaultLng ? (defaultLng === 'en' ? FAQDataSource.en : FAQDataSource.id) : FAQDataSource.id)
    }

    initiate()
  }, [])

  return (
    <div {...rest} {...wrapper}>
      <OverPack {...overPack}>
        <div className="title-wrapper">
          <h2 className="title-h1">FAQ</h2>
          <h2 className="title-h1">"{t('faq.description')}"</h2>
        </div>
        <TweenOne
          animation={{ x: '+=30', opacity: 0, type: 'from' }}
          key="faqs"
          className="content-faq"
        >
          <Collapse defaultActiveKey={['0']} onChange={key => console.log(key)} accordion>
            {data.map((item, index) => (
              <Panel header={item.question} key={index}>
                <div dangerouslySetInnerHTML={{ __html: item.answer}} />
              </Panel>
            ))}
          </Collapse>
        </TweenOne>
      </OverPack>
    </div>
  )
}

export default FAQ