/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import TweenOne from 'rc-tween-one'
import { Button, Menu, Radio, RadioChangeEvent } from 'antd'
import { IHomeComponent } from './types'
import GlobeIcon from '../Icons/Globe'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ReactGA from "react-ga4"
import { postAnalyticClick } from '../../services/demo'
import whatsapp from '../../assets/images/whatsapp_icon.png'

const screenWidth = window.innerWidth
ReactGA.initialize("G-3GPB9BV30L")

const Header: React.FC<IHomeComponent> = ({dataSource, isMobile, ...rest }) => {
  const {i18n, t} = useTranslation()

  const headerRef = useRef<any>(null)
  const tweenOneRef = useRef<any>(null)

  const [language, setLanguage] = useState<string>('id')

  const [phoneOpen, openPhone] = useState<boolean>(false)

  const navData = dataSource.Menu.children
  const moment = phoneOpen === undefined ? 300 : null

  useEffect(() => {
    const initiate = async () => {
      const defaultLng = localStorage.getItem('@defaultLng')
      setLanguage(defaultLng ? defaultLng : 'id')
    }

    initiate()
  }, [i18n])

  const phoneClick = () => {
    openPhone(!phoneOpen)
  }

  const changeLanguage = ({ target: { value } }: RadioChangeEvent) => {
    i18n.changeLanguage(value)
    setLanguage(value)
    localStorage.setItem('@defaultLng', value)
    window.location.reload()
  }

  const items: any = [
    {
      label: "IDN",
      value: "id"
    }, {
      label: "ENG",
      value: "en",
    }
  ];

  const callNow = async () => {
    await postAnalyticClick('call_now_clicks')
    window.location.href = 'http://wa.me/6281285059678'
  }

  useEffect(()=>{
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search })
  }, [])

  return (
    <TweenOne
      component="header"
      animation={{ opacity: 0, type: 'from' }}
      {...dataSource.wrapper}
      {...rest}
    >
      <div
        {...dataSource.page}
        ref={headerRef}
        className={`${dataSource.page.className}${phoneOpen ? ' open' : ''}`}
      >
        <TweenOne
          animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
          {...dataSource.logo}
        >
          <Link to="/">
            <img src={dataSource.logo.children} alt="EasyGo" />
          </Link>
        </TweenOne>
        {(isMobile || screenWidth <= 834) && (
          <div
            {...dataSource.mobileMenu}
            onClick={() => phoneClick()}
          >
            <em />
            <em />
            <em />
          </div>
        )}
        <TweenOne
          {...dataSource.Menu}
          ref={tweenOneRef}
          animation={
            (isMobile || screenWidth <= 834)
              ? {
                  x: 0,
                  height: 0,
                  duration: 300,
                  onComplete: (e) => {
                    const header = headerRef.current;
                    if (header && header.classList.contains('open')) {
                      console.log(`${window.screen.height}px`)
                      e.target.style.height = screenWidth <= 550 ? `${window.screen.height-120}px` : `${window.screen.height-65}px`
                      // e.target.style.height = "91vh"
                    }
                  },
                  ease: 'easeInOutQuad',
                }
              : null
          }
          moment={moment}
          reverse={!!phoneOpen}
        >
          <Menu
            mode={(isMobile || screenWidth <= 834) ? 'inline' : 'horizontal'}
            theme="light"
            defaultSelectedKeys={[]}
            items={navData}
          />

          <div className='header-right-menu'>
            <Button type="default" shape="round" size="large" onClick={() => callNow()}>
              <div className='btn-column' style={{display:"flex",flexDirection:"row",justifyContent:"center",paddingTop:"3px"}}>
                <div><img src={whatsapp} width="30px" alt="EasyGo" /></div>
                <div>
                  {/* <span>{t('common.call.now')}</span> */}
                  <span>+62 812-85059678</span>
                </div>
                
              </div>
            </Button>
            <Button type="primary" shape="round" size="large" onClick={dataSource.handleDemoModal}>
              {t('common.demo')}
            </Button>

            <div className="language-switcher">
              <GlobeIcon />
              <Radio.Group options={items} onChange={changeLanguage} value={language} optionType="button" />
            </div>
          </div>
        </TweenOne>
      </div>
    </TweenOne>
  )
}

export default Header
