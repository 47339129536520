import TweenOne from 'rc-tween-one'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { IStaticComponent } from "./types"
import { Col, Row, Typography, Button, Form, Input, Upload, Select, Space } from 'antd'
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import Logo from '../../assets/images/Logo_white.png' 
import Barcode from '../../assets/images/barcode.png'
import YoutubeIcon from '../Icons/Youtube'
import InstagramIcon from '../Icons/Instagram'
import FacebookIcon from '../Icons/Facebook'
import LinkedInIcon from '../Icons/LinkedIn'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';



const qs = require('qs');

const { Text, Link } = Typography

const PreFooter: React.FC<IStaticComponent> = ({isMobile, ...rest }) => {
    const {t} = useTranslation()
    const wrapper = { className: 'home-page-wrapper job-wrapper' }
    const overPack = { className: 'home-page pre-footer', playScale: 0.05 }
    const [jobs,setJobs] = useState<any>([])
    const [upload_link, setuploadLink] = useState<string>('');
    const [uploadFile, setUploadFile] = useState<any>()
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [form] = Form.useForm();
    const [commitSuccess,setCommitSuccess] = useState<any>(false)


    useEffect(()=>{
        init()
    },[])

    const init = async () => {
        const url:any = process.env.REACT_APP_API_CONTENT+'/jobs'
        const params = {
            project_id: process.env.REACT_APP_PROJECT_ID,
            rows: 1000,
            page: 1
        }
        const data = await axios.get(url+'?'+qs.stringify(params))
        
        console.log('data',data.data.data.data)
        if(data.data.status){
            setJobs(data.data.data.data)
        }
    }

    const onFinish = async (values: any) => {
        console.log('Success:', values);

       
        if(!uploadFile){
            return false
        }else{
            // upload to CDN
            const cdn_user:any = process.env.REACT_APP_CDN_USERNAME
            const cdn_pass:any = process.env.REACT_APP_CDN_PASSWORD
            console.log('file',uploadFile)
            const formData = new FormData();
            formData.append('file', uploadFile);
            formData.append('email',cdn_user)
            formData.append('password',cdn_pass)
     
     
            // Kirim file ke backend menggunakan Axios
            const url:any = process.env.REACT_APP_CDN_URL

            try {
                const res_upload:any = await axios.post(url, formData)
                console.log('res_upload',res_upload)
                if(res_upload.data.status){
                    console.log('url',res_upload.data.url)
                    const params = {
                        project_id : process.env.REACT_APP_PROJECT_ID,
                        job_id: values.job_id,
                        email: values.email,
                        name: values.name,
                        phone: values.phone,
                        document: res_upload.data.data.url 
                    }

                    const config = {
                        headers: {
                            "Content-Type": 'application/json',
                            "Accept": "application/json",
                        },
                    }

                    try {
                        const post_seeker:any = await axios.post(process.env.REACT_APP_API_CONTENT+'/jobs/seeker',JSON.stringify(params),{
                            headers: {
                                "Content-Type": 'application/json',
                                "Accept": "application/json",
                            }
                        })

                        console.log('post_seeker',post_seeker)
                        if(post_seeker.data.status){
                            form.resetFields();
                            if (fileInputRef.current) {
                                fileInputRef.current.value = '';
                            }
                            // sweetalert
                            setCommitSuccess(true)
                        }
                    } catch (error) {
                        return false
                    }
                }

            } catch (error) {
                return false
            }
            
        }
        // write
    };
      
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const handleUpload = (file:any) => {
        const cdn_user:any = process.env.REACT_APP_CDN_USERNAME
        const cdn_pass:any = process.env.REACT_APP_CDN_PASSWORD
        console.log('file',file)
        const formData = new FormData();
        formData.append('file', file);
        formData.append('email',cdn_user)
        formData.append('password',cdn_pass)


        // Kirim file ke backend menggunakan Axios
        const url:any = process.env.REACT_APP_CDN_URL
        axios.post(url, formData)
            .then(response => {
            // Tangani respons dari backend
            // Misalnya, tampilkan pesan sukses atau error
        })
        .catch(error => {
            // Tangani kesalahan yang terjadi saat mengirim file
        });
    }

    const actionUpload = (st:string) => {
        console.log('data&&&&&', st)

    }

    const handleAction = (res:any) => {
        console.log('res',res)
    }

    

    const handleFileUpload = (file:any) =>{
        setUploadFile(file.target.files[0])
        
    }

    const DeleteUploadFile = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }

        setUploadFile(null)
    }
    
    return (
        <div {...rest} {...wrapper}>
            <OverPack {...overPack}>
                <TweenOne
                animation={{ x: '+=30', opacity: 0, type: 'from' }}
                key="card"
                className="footer-copyright"
                >
                    <div className="title-wrapper">
                        <h2>{t("job.job.title")}</h2>
                    </div>
                    
                    <Row gutter={[12, 16]} style={{justifyContent:"center"}}>

                    {jobs.length > 0 ?(
                        jobs.map((job:any,index:any)=>(
                            <Col key={index} className="job-card" lg={{ span: 11, offset: 1 }} xs = {{span:22, offset: 1}}>
                                <div className='title'>{job.name}</div>
                                <Row>
                                    <Col xl={{span: 11, offset: 0}}>
                                        <div className='label'>{t("job.category")}</div>
                                        <div className='content'>{job.job_category.name}</div>
                                    </Col>
                                    <Col xl={{span: 11, offset: 0}}>
                                        <div className='label'>{t("job.level")}</div>
                                        <div className='content'>{job.job_level.name}</div>
                                    </Col>    
                                </Row>
                                
                                <div className='label' style={{marginTop:10}}>{t("job.requirements")}</div>
                                {/* <div className="post-content" dangerouslySetInnerHTML={{ __html: article.content.content_languages[0].full_content}} /> */}
                                <div className='requirement' dangerouslySetInnerHTML={{ __html: job.requirement}}/>

                                <div className='label' style={{marginTop:5}}>{t("job.responsibilities")}</div>
                                {/* <div className="post-content" dangerouslySetInnerHTML={{ __html: article.content.content_languages[0].full_content}} /> */}
                                <div className='requirement' dangerouslySetInnerHTML={{ __html: job.responsibilities}}/>
                                
                                <div className='label'>{t("job.closing.date")}</div>
                                <div className='content'>{moment(job.close).format("MMM Do, YYYY")}</div>
                                
                            </Col>

                        ))
                    ):(
                        <h2 className='no-job'>{t("job.nojob")}</h2>
                    )}
                    </Row>
                    
                </TweenOne>

                <TweenOne
                    animation={{ x: '+=30', opacity: 0, type: 'from' }}
                    key="form"
                    className="jobseeker-wrapper"
                >

                    
                    <Form
                        name="jobseeker"
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 16 }}
                        // style={{ maxWidth: 600 }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        form={form}
                    >

                        <Form.Item
                            label="Job"
                            name="job_id"
                        >
                            <Select
                                
                                id="job_id"
                            >
                                {jobs.map((job:any,index:any)=>(
                                    <Select.Option value={job.job_id} key={index}>{job.name}</Select.Option>
                                ))}
                            </Select>    
                        </Form.Item>
                        

                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ required: true, message: 'Please input your email!' }]}
                        >
                            <Input type="email" placeholder="Enter your email" />
                        </Form.Item>

                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: 'Please input your name' }]}
                        >
                            <Input placeholder="Enter your name" />
                        </Form.Item>

                        <Form.Item
                            label="Phone"
                            name="phone"
                            rules={[{ required: true, message: 'Please input your phone' }]}
                        >
                            <Input placeholder="Enter your phone" />
                        </Form.Item>
                        
                        <Form.Item 
                            wrapperCol={{ offset: 4, span: 16 }}
                        >
                            <div className='label-info'>{t("job.document.info")}</div>
                            {/* <Upload 
                                // action={'https://cdn.mediacartz.com/api/store'                   }                                                                                                       
                                beforeUpload={handleUpload}
                                maxCount={1}
                            >
                                <Button icon={<UploadOutlined />}>{t("job.upload")}</Button>
                            </Upload> */}
                            <div style={{display:"flex"}}>
                                <input type="file" id="myFile" name="myFile" onChange={(file)=>handleFileUpload(file)} ref={fileInputRef}></input>    
                                {uploadFile ? (
                                <DeleteOutlined style={{paddingTop: "8px", paddingLeft:"20px"}} onClick={DeleteUploadFile}/>
                                ):('')}
                            </div>
                            <div className='error-file'>{uploadFile ? "": t("job.file.empty")}</div>

                        </Form.Item>
                        


                        <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>

                            {commitSuccess ? (
                                <div className='commit-success'>
                                    {t("job.commit.success")}
                                </div>
                            ):('')}
                        </Form.Item>

                        

                    </Form>
                </TweenOne>
            </OverPack>
        </div>
    )
}
    
export default PreFooter