import TweenOne from 'rc-tween-one'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { IStaticComponent } from "./types"
import { Avatar, Card, Col, Image, Row, Typography } from 'antd'

import Testimoni1 from '../../assets/images/testimonials/1.png'
import Testimoni2 from '../../assets/images/testimonials/2.png'
import Testimoni3 from '../../assets/images/testimonials/3.png'

const {Title} = Typography

const Testimoni: React.FC<IStaticComponent> = ({isMobile, ...rest }) => {
  const wrapper = { className: 'home-page-wrapper testimoni-wrapper' }
  const overPack = { className: 'home-page testimoni', playScale: 0.05 }

  const testimonials = [{
    avatar: Testimoni1,
    name: "Putra Lingga Tan",
    title: "Commissioner of PT Mega Samudra Tama",
    description: '"We feel that what we have done is the best, there are many details and options that can facilitate what MST wants, and the service is quick to respond when we complain."'
  }, {
    avatar: Testimoni2,
    name: "Rizky Galuh Pratama",
    title: "Chief Financial Officer of PT Pratama Galuh Perkasa",
    description: '"With EasyGO GPS, it is easier to monitor drivers, maintain fleets and also make quick decisions in analyzing fleet productivity only through TMS from EasyGO"'
  }, {
    avatar: Testimoni3,
    name: "Suryana",
    title: "Manager Operational of PT Gemilang Sukses Mandiri",
    description: '"We chose EasyGO because of good service. User friendly and effective application in monitoring the fleet. Very satisfying and very helpful for EasyGO services"'
  }]

  return (
    <div {...rest} {...wrapper}>
      <OverPack {...overPack}>
        <div className="title-wrapper">
          <h2 className="title-h1">Apa Kata Pelanggan</h2>
        </div>
        <TweenOne
          animation={{ x: '+=30', opacity: 0, type: 'from' }}
          key="testimoni"
          className="testimoni"
        >
          <Row>
            {testimonials.map((item, index) => (
              <Col md={24} lg={8} key={index}>
                <Card className="centered-card" hoverable>
                  <Avatar size={80} src={<Image src={item.avatar} style={{ width: 80 }} />} />
                  <Title level={5}>{item.name}</Title>
                  <div className="testimoni-title">{item.title}</div>
                  <div className="testimoni-content">{item.description}</div>
                </Card>
              </Col>
            ))}
          </Row>
        </TweenOne>
      </OverPack>
    </div>
  )
}

export default Testimoni