import TweenOne from 'rc-tween-one'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import moment from 'moment'
import { IStaticComponent } from "./types"
import { Card, Col, Row, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { paginatePosts } from '../../services/posts'
import ArrowRightIcon from '../Icons/ArrowRight'
import { useTranslation } from 'react-i18next'

const { Text } = Typography
const { Meta } = Card

const RecentPost: React.FC<IStaticComponent> = ({isMobile, ...rest }) => {
  const {t} = useTranslation()
  
  const wrapper = { className: 'home-page-wrapper recent-post-wrapper' }
  const overPack = { className: 'home-page recent-post', playScale: 0.05 }

  const [articles, setArticles] = useState<any[]>([])

  useEffect(() => {
    const initiate = async () => {
      const defaultLng = localStorage.getItem('@defaultLng')
      const language = defaultLng ? defaultLng : 'id'
      const category = language === 'id' ? 71 : 141
      const posts = await paginatePosts(category, 1, 3)
      setArticles(posts)
    }

    initiate()
  }, [])

  const getArticle = (article: any, index: number) => {
    return (
      <Col md={24} lg={8} key={index}>
        <Card
          className="article-card"
          hoverable
          cover={<img alt={article.title.rendered} src={article._embedded['wp:featuredmedia'][0]['media_details']['sizes']['medium']['source_url']} />}
          onClick={() => window.location.href = `/details/${article.slug}`}
        >
          <Row>
            <Col span={12}>
              <Text type="secondary">{moment(article.date).format('MMM DD, YYYY')}</Text>
            </Col>
            <Col span={12} className="right">
              <Text type="secondary">{t('common.by')} {article._embedded['author'][0]['name']}</Text>
            </Col>
          </Row>
          <Meta
            title={(<Text strong >{article.title.rendered}</Text>)}
          />
          <div className="right-button">
            <ArrowRightIcon />
          </div>
        </Card>
      </Col>
    )
  }

  return (
    <div {...rest} {...wrapper}>
      <OverPack {...overPack}>
        <div className="title-wrapper">
          <h2 className="title-h1">Update Terbaru</h2>
        </div>
        <TweenOne
          animation={{ x: '+=30', opacity: 0, type: 'from' }}
          key="recent-post"
          className="recent-post"
        >
          {articles && (
            <Row>
              {articles.map((item: any, index: number) => getArticle(item, index))}
            </Row>
          )}
        </TweenOne>
      </OverPack>
    </div>
  )
}

export default RecentPost