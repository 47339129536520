import { useState } from 'react'
import { Col, Image } from 'antd'

type Props = {
  index: number
  title: string
  image: any
  active: any
  setActive: any
  selectedVehicle?: any
  selectedReqs?: any
  interest?: boolean
}

const SolutionImage: React.FC<Props> = ({index, title, image, active, setActive, selectedVehicle, selectedReqs, interest}) => {
  const [hover, isHover] = useState(false);

  return (
    <Col
      key={index}
      lg={6}
      md={12}
      className="vehicle-type-col"
      onMouseEnter={() => isHover(true)}
      onMouseLeave={() => isHover(false)}
      onClick={() => interest ? (active ? (active.includes(index) ? setActive([...active.filter((item: number) => item !== index)]) : setActive([...active, index])) : setActive([index])) : setActive(index)}
    >
      <div className="vehicle-type-image">
        <Image
          width={interest ? 75 : 150}
          alt={title}
          src={image}
          preview={false}
          style={{
            filter: (hover || active === index || (interest && active && active.includes(index)) || selectedVehicle === index || (selectedReqs && selectedReqs.includes(index))) ? (interest ? 'invert(41%) sepia(93%) saturate(1015%) hue-rotate(341deg) brightness(102%) contrast(95%)' : 'none') : 'grayscale(100%)',
          }}
        />
      </div>
      <h4 className="title-h4 text-center" style={{ color: (hover || active === index || (interest && active && active.includes(index)) || selectedVehicle === index || (selectedReqs && selectedReqs.includes(index))) ? "#F96632" : "#202020"}}>{title}</h4>
    </Col>
  )
}

export default SolutionImage