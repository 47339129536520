import TweenOne from 'rc-tween-one'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { IHomeComponent } from "./types"

const Footer: React.FC<IHomeComponent> = ({dataSource, isMobile, ...rest }) => {
  const wrapper = { className: 'home-page-wrapper footer2-wrapper' }
  const overPack = { className: 'home-page footer2', playScale: 0.05 }

  return (
    <div {...rest} {...wrapper}>
      <OverPack {...overPack}>
        <TweenOne
          animation={{ x: '+=30', opacity: 0, type: 'from' }}
          key="copyright"
          className="footer-copyright"
        >
          <span>Copyright © 2021 <a href="https://easygo-gps.co.id/">PT EASYGO INDONESIA</a> - Created by <a href="/">GuePikirin</a>. All Rights Reserved.</span>
        </TweenOne>
      </OverPack>
    </div>
  )
}

export default Footer