import { useEffect, useState } from 'react'
import { enquireScreen } from 'enquire-js'
import { HeaderNavDataSource } from '../components/Home/data.source'
import Header from '../components/Home/Header'

import '../components/Home/less/antMotionStyle.less'
import Footer from '../components/Home/Footer'
import PreFooter from '../components/Home/PreFooter'
import DemoModal from '../components/Home/DemoModal'

import TweenOne from 'rc-tween-one'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import NotFoundIcon from '../components/Icons/NotFoundIcon'
import { postAnalyticClick } from '../services/demo'

let isThisMobile : boolean
enquireScreen((b : boolean) => {
  isThisMobile = b
})

const NotFound = () => {
  const [isMobile, setIsMobile] = useState(isThisMobile)
  const [show, isShown] = useState(false)

  const [demoModalOpen, isDemoModalOpen] = useState(false)

  useEffect(() => {
    enquireScreen((b : boolean) => {
      setIsMobile(!!b)
    })
    
    if (window.location.port) {
      setTimeout(() => {
        isShown(true)
      }, 500)
    } else {
      isShown(true)
    }
  }, [isMobile, show])

  const showDemoModal = async () => {
    isDemoModalOpen(!demoModalOpen)
    await postAnalyticClick('demo_clicks')
  }

  const Page404: any = () => {
    const wrapper = { className: 'home-page-wrapper page-404-wrapper' }
    const overPack = { className: 'home-page page-404', playScale: 0.05 }

    return (
      <div {...wrapper}>
        <OverPack {...overPack}>
          <div className="title-wrapper">
            <h2 className="title-h1">Error</h2>
          </div>
          <TweenOne
            animation={{ x: '+=30', opacity: 0, type: 'from' }}
            key="not-found"
            className="content-404"
          >
            <NotFoundIcon width={window.innerWidth && 350} />
            <div className="not-found-caption">
              We can’t seem to find the page you are looking for!
            </div>
          </TweenOne>
        </OverPack>
      </div>
    )
  }

  const children = [
    <Header
      dataSource={HeaderNavDataSource(showDemoModal)}
      isMobile={isMobile}
      key="Header"
    />,
    <Page404
      key="Page404"
    />,
    <PreFooter
      isMobile={isMobile}
      key="Pre-Footer"
    />,
    <Footer
      dataSource={[]}
      isMobile={isMobile}
      key="Footer"
    />,
  ]

  return (
    <div
      className="templates-wrapper"
    >
      {show && children}

      <DemoModal modalOpen={demoModalOpen} isModalOpen={isDemoModalOpen} />
    </div>
  )
}

export default NotFound
