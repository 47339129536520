import { useEffect, useState } from 'react'
import { Card, Col, Collapse, Image, Modal, Row } from 'antd'
import TweenOne from 'rc-tween-one'
import CollapsePanel from 'antd/lib/collapse/CollapsePanel'
import { TechDetailSource } from './data.source'

type Props = {
  id?: string
  title: string
  image: any
}

const ProductImage: React.FC<Props> = ({id, title, image}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [readmore, setReadMore] = useState<boolean>(false);
  const [data, setData] = useState<any>({})

  useEffect(() => {
    const initiate = async () => {
      const defaultLng = localStorage.getItem('@defaultLng')
      const dataSource = defaultLng ? (defaultLng === 'en' ? TechDetailSource.en : TechDetailSource.id) : TechDetailSource.id
      setData(dataSource[id as keyof typeof dataSource])
    }

    initiate()
  }, [])

  useEffect(() => {
    setReadMore(false)
  }, [visible])

  return (
    <>
      <div className="content-image">
        <Card className="centered-card" hoverable onClick={() => setVisible(true)}>
          <Image
            width="100%"
            src={image}
            preview={false}
            style={{
              objectFit: 'cover',
              marginBottom: 10
            }}
          />
          <span className="tech-title" dangerouslySetInnerHTML={{__html: title}} />
        </Card>
      </div>

      <Modal 
        open={visible} 
        onCancel={()=>setVisible(false)}
        closeIcon={null}
        closable={false}
        footer={null}
        bodyStyle={{backgroundColor: 'rgba(217, 217, 217, 1)', padding:0, paddingTop: 40, paddingBottom:40}}
      >
        <div className="modal-tech-detail">
          <Row align="middle">
            <Col span={17}>
              <div className="title-wrapper" style={{padding:20}}>
                <h2 className="title-h1"><strong>{data.name}</strong></h2>
                {
                  data.description &&
                  <div className="title-description" style={{paddingTop:8}}>{data.description}</div>
                }
                {
                  data.description2 &&
                  <div>
                    {
                      !readmore ? (
                        <div className="readmore" onClick={()=>setReadMore(true)}>Baca Lebih Lanjut..</div>
                      ) : (
                        <TweenOne
                          animation={{ y: '-=30', opacity: 0, type: 'from' }}
                        >
                          {data.description2}
                        </TweenOne>
                      )
                    }
                  </div>
                }
              </div>
              <div className="tech-collapse-container">
                <Collapse accordion expandIconPosition="right">
                  {
                    data?.data?.map((item:any, index:number) => (
                      <CollapsePanel header={item.name} key={index}>
                        {
                          item.data.map((itm:any, idx:number) => (
                            <Row key={idx}>
                              {
                                item.type==='icon' &&
                                <Col style={{marginRight:10}}><img src={itm.icon} width="16px" alt="EasyGo" /></Col>
                              }
                              {
                                item.type==='list' &&
                                <Col style={{marginRight:10}}>•</Col>
                              }
                              <Col flex="1">{itm.name}</Col>
                            </Row>
                          ))
                        }
                      </CollapsePanel>
                    ))
                  }
                </Collapse>
              </div>
            </Col>
            <Col span={7}>
              <img src={data.image} width="100%" alt="EasyGo" />
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  )
}

export default ProductImage