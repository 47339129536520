import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import { IHomeComponent } from "./types"
import { getChildrenToRender } from "./utils"
import { Player } from "video-react"
import { Button, Modal } from 'antd'
import { PlayCircleFilled } from '@ant-design/icons'
import { useEffect, useRef, useState } from 'react'

import BannerVideo from "../../assets/videos/EASYGO.mp4"
import CloseIcon from '../Icons/Close'

const Banner : React.FC<IHomeComponent> = ({dataSource, isMobile, isNotHome, ...rest }) => {
  const vRef = useRef<any>(null)
  const [modalOpen, isModalOpen] = useState(isNotHome?false:true)

  const animType = {
    queue: 'bottom',
    one: {
      y: '+=30',
      opacity: 0,
      type: 'from',
      ease: 'easeOutQuad',
    },
  }

  const openModal = () => {
    isModalOpen(true)
    setTimeout(() => {
      vRef.current?.play()
    }, 1000);
  }

  const cancelModal = () => {
    vRef.current?.pause()
    isModalOpen(false)
  }
  
  return (
    <div {...rest} {...dataSource.wrapper}>
      <div {...dataSource.page}>
        <QueueAnim
          key="text"
          type={animType.queue}
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          {...dataSource.childWrapper}
          componentProps={{
            md: dataSource.childWrapper.md,
            xs: dataSource.childWrapper.xs,
          }}
        >
          {dataSource.childWrapper.children.map(getChildrenToRender)}
        </QueueAnim>
        <TweenOne animation={animType.one} key="title" {...dataSource.image}>
          <div className="bg-image-btn">
            <Button
              type="text"
              shape="circle"
              size="large"
              icon={<PlayCircleFilled style={{ fontSize: 100 }} />}
              onClick={() => openModal()}
            />
          </div>
          <div className="bg-image" />
          <video autoPlay muted loop src={BannerVideo} />
        </TweenOne>
      </div>

      <Modal
        className="video-modal no-header no-footer"
        open={modalOpen}
        onCancel={() => cancelModal()}
        centered
      >
        <div className="close-banner" onClick={()=>isModalOpen(false)}>
          <CloseIcon width={30} height={30}/>
        </div>
        <Player ref={vRef} autoPlay muted>
          <source src={BannerVideo} type="video/mp4" />
        </Player>
      </Modal>
    </div>
  )
}

export default Banner
