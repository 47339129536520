import TweenOne from 'rc-tween-one'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { IStaticComponent } from "./types"
import { Card, Col, Image, Row, Typography } from 'antd'
import { IndustryDataSource } from './data.source'
import { useEffect, useState } from 'react'

import Industry1PNG from "../../assets/images/industries/1.png"
import Industry2PNG from "../../assets/images/industries/2.png"
import Industry3PNG from "../../assets/images/industries/3.png"
import Industry4PNG from "../../assets/images/industries/4.png"
import Industry5PNG from "../../assets/images/industries/5.png"
import Industry6PNG from "../../assets/images/industries/6.png"
import Industry7PNG from "../../assets/images/industries/7.png"
import Industry8PNG from "../../assets/images/industries/8.png"
import Industry9PNG from "../../assets/images/industries/9.png"
import Industry10PNG from "../../assets/images/industries/10.png"
import Industry11PNG from "../../assets/images/industries/11.png"
import Industry12PNG from "../../assets/images/industries/12.png"
import Industry13PNG from "../../assets/images/industries/13.png"
import Industry14PNG from "../../assets/images/industries/14.png"
import { paginatePosts } from '../../services/posts'
import ArrowRightIcon from '../Icons/ArrowRight'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const { Text } = Typography
const { Meta } = Card

const Industries: React.FC<IStaticComponent> = ({isMobile, ...rest }) => {
  const { t } = useTranslation()

  const wrapper = { className: 'home-page-wrapper industry-wrapper' }
  const overPack = { className: 'home-page industry', playScale: 0.05 }
  const overPackSecondary = { className: 'home-page industry secondary', playScale: 0.05 }
  
  const [data, setData] = useState<any>()
  const [posts, setPosts] = useState<any[]>([])

  useEffect(() => {
    const initiate = async () => {
      const defaultLng = localStorage.getItem('@defaultLng')
      setData(defaultLng ? (defaultLng === 'en' ? IndustryDataSource.en : IndustryDataSource.id) : IndustryDataSource.id)

      const videos = await paginatePosts("345,343", 1, 3)
      setPosts(videos)
    }

    initiate()
  }, [])

  const getImage = (index: number) => {
    switch (index) {
      case 0:
        return Industry1PNG
      case 1:
        return Industry2PNG
      case 2:
        return Industry3PNG
      case 3:
        return Industry4PNG
      case 4:
        return Industry5PNG
      case 5:
        return Industry6PNG
      case 6:
        return Industry7PNG
      case 7:
        return Industry8PNG
      case 8:
        return Industry9PNG
      case 9:
        return Industry10PNG
      case 10:
        return Industry11PNG
      case 11:
        return Industry12PNG
      case 12:
        return Industry13PNG
      case 13:
        return Industry14PNG
      default:
        return Industry10PNG
    }
  }

  const getVideoPost = (article: any, index: number) => {
    const video_link = article.acf.video_link
    const ytCode = video_link.substring(video_link.lastIndexOf('/') + 1)
    return (
      <Col md={24} lg={8} key={index}>
        <Card
          className="article-card"
          hoverable
          cover={
            <iframe
              height="200"
              src={`https://www.youtube.com/embed/${ytCode}`}
              title={article.title.rendered}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              className="card-iframe"
              allowFullScreen></iframe>
          }
          onClick={() => window.location.href = `/details/${article.slug}`}
        >
          <Row>
            <Col span={12}>
              <Text type="secondary">{moment(article.date).format('MMM DD, YYYY')}</Text>
            </Col>
            <Col span={12} className="right">
              <Text type="secondary">{t('common.by')} {article._embedded['author'][0]['name']}</Text>
            </Col>
          </Row>
          <Meta
            title={(<span className="ant-typography"><strong dangerouslySetInnerHTML={{__html: article.title.rendered}} /></span>)}
          />
          <div className="right-button">
            <ArrowRightIcon />
          </div>
        </Card>
      </Col>
    )
  }

  return (
    <div {...rest} {...wrapper}>
      <OverPack {...overPack}>
        <div className="title-wrapper">
          <h2 className="title-h1">{data && data.title}</h2>
        </div>
        <TweenOne
          animation={{ x: '+=30', opacity: 0, type: 'from' }}
          key="industry"
          className="content-industry"
        >
          {data && (
            <Row wrap justify={'center'}>
              {data.list.map((item: any, index: number) => (
                <div className="col-5" key={index}>
                  <Card className="centered-card" hoverable style={{height:'100%'}}>
                    <Image
                      width="100%"
                      src={getImage(index)}
                      preview={false}
                      style={{
                        objectFit: 'cover',
                        marginBottom: 10
                      }}
                    />
                    <span className="text-center">{item}</span>
                  </Card>
                </div>
              ))}
            </Row>
          )}
        </TweenOne>
      </OverPack>
      <OverPack {...overPackSecondary}>
        <div className="title-wrapper">
          <h2 className="title-h1">{data && data.secondTitle}</h2>
          <p>{data && data.secondSubtitle}</p>
        </div>
        <TweenOne
          animation={{ x: '+=30', opacity: 0, type: 'from' }}
          key="industry"
          className="content-industry"
        >
          {posts && (
            <Row>
              {posts.map((item: any, index: number) => getVideoPost(item, index))}
            </Row>
          )}
        </TweenOne>
      </OverPack>
    </div>
  )
}

export default Industries