import axios from "axios"
import { BASE, YARPP, API_CONTENT } from "./init"

export const paginatePosts = async (category: number | string, page: number, per_page: number) => {
  const response = await axios.get(`${BASE}/posts&_embed=true&categories=${category}&per_page=${per_page}&page=${page}&orderby=date&order=desc&exclude=7618,7621,7626,7623,7632,7630`, {
    headers: {
      "Content-Type": 'application/json',
      "Accept": "application/json",
    },
  })

  if (response.status === 200) {
    if (response.data) {
      return response.data
    } else {
      return null
    }
  }

  return null
}

export const getPostBySlug = async (slug: string) => {
  const response = await axios.get(`${BASE}/posts&_embed=true&slug=${slug}`, {
    headers: {
      "Content-Type": 'application/json',
      "Accept": "application/json",
    },
  })

  if (response.status === 200) {
    if (response.data) {
      return response.data[0]
    } else {
      return null
    }
  }

  return null
}

export const getAttachment = async (id: number) => {
  const response = await axios.get(`${BASE}/media/${id}`, {
    headers: {
      "Content-Type": 'application/json',
      "Accept": "application/json",
    },
  })

  if (response.status === 200) {
    if (response.data) {
      return response.data
    } else {
      return null
    }
  }

  return null
}

export const downloadFile = async (url: string) => {
  const response = await axios.get(url, {
    headers: {
      "Content-Type": 'application/json',
      // "Content-disposition": "attachment; filename=" + "server-dummy.pdf",
    },
    responseType: 'blob',
  })

  console.log(response)

  // if (response.status === 200) {
  //   if (response.data) {
  //     return response.data
  //   } else {
  //     return null
  //   }
  // }

  return null
}

export const getRelatedPosts = async (postId: number) => {
  const response = await axios.get(`${YARPP}/related/${postId}&limit=3&_embed=true`, {
    headers: {
      "Content-Type": 'application/json',
      "Accept": "application/json",
    },
  })

  if (response.status === 200) {
    if (response.data) {
      return response.data
    } else {
      return null
    }
  }

  return null
}


export const paginateContent = async (content_type_id: number, language_id: number, page: number, per_page: number) => {
  const response = await axios.get(`${API_CONTENT}/contents`, {
    headers: {
      "Content-Type": 'application/json',
      "Accept": "application/json",
    },
    params: {
      project_id: 1,
      content_type_id: content_type_id,
      language_id: language_id,
      page: page,
      rows: per_page
    }
  })

  if (response.status === 200) {
    if (response.data) {
      return response.data.data
    } else {
      return null
    }
  }

  return null
}

export const getContentBySlug = async (slug: string, language_id: number) => {
  const response = await axios.get(`${API_CONTENT}/contents/get`, {
    headers: {
      "Content-Type": 'application/json',
      "Accept": "application/json",
    },
    params: {
      slug: slug,
      language_id: language_id
    }
  })

  if (response.status === 200) {
    if (response.data) {
      return response.data.data
    } else {
      return null
    }
  }

  return null
}

export const getRelatedContent = async (content_type_id: number, language_id: number) => {
  const response = await axios.get(`${API_CONTENT}/contents`, {
    headers: {
      "Content-Type": 'application/json',
      "Accept": "application/json",
    },
    params: {
      project_id: 1,
      content_type_id: content_type_id,
      language_id: language_id,
      page: 1,
      rows: 3
    }
  })

  if (response.status === 200) {
    if (response.data) {
      return response.data.data
    } else {
      return null
    }
  }

  return null
}