import ReactDOM from 'react-dom'
import './assets/styles.less'
import "video-react/dist/video-react.css";
import App from './App'
import reportWebVitals from './reportWebVitals'

import configureStore from './configureStore'
import 'typeface-ibm-plex-sans'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

const initialState = window.INITIAL_REDUX_STATE
const store = configureStore(history, initialState)

ReactDOM.render(
  <App store={store} history={history} />,
  document.getElementById('root')
)

reportWebVitals()
