import TweenOne from 'rc-tween-one'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { IStaticComponent } from "./types"
import { Col, Row, Typography } from 'antd'
import Logo from '../../assets/images/Logo_white.png' 
import Barcode from '../../assets/images/barcode.png'
import YoutubeIcon from '../Icons/Youtube'
import InstagramIcon from '../Icons/Instagram'
import FacebookIcon from '../Icons/Facebook'
import LinkedInIcon from '../Icons/LinkedIn'
import { useTranslation } from 'react-i18next'

const { Text, Link } = Typography

const PreFooter: React.FC<IStaticComponent> = ({isMobile, ...rest }) => {
  const {t} = useTranslation()
  const wrapper = { className: 'home-page-wrapper pre-footer-wrapper' }
  const overPack = { className: 'home-page pre-footer', playScale: 0.05 }

  return (
    <div {...rest} {...wrapper}>
      <OverPack {...overPack}>
        <TweenOne
          animation={{ x: '+=30', opacity: 0, type: 'from' }}
          key="copyright"
          className="footer-copyright"
        >
          <Row>
            <Col  md={8} sm={24}>
              <img width={100} src={Logo} alt="EasyGo" />
              <div className='footer-column'>
                <Text className='label'>{t('common.address')}</Text>
                <Text>Jl. Parang Tritis Raya Komplek Indo Ruko Lodan No. 1AB, Jakarta Utara - Indonesia</Text>
              </div>
              <div className='footer-column'>
                <Text className="label">{t("common.phone")}</Text>
                <Text>+6221 698 300 38 ({t("common.office")})</Text>
                {/* <Text>+6221 698 300 39 ({t('common.fax')})</Text> */}
                <Text>+62812 8505 9678 ({t("common.marketing")})</Text>
                <Text>+62811 1562 085 ({t("common.cs")})</Text>              </div>
            </Col>
            <Col  md={8} sm={24}>
              <div className='footer-column with-m-top' style={{ textAlign: 'center' }}>
                <Link href='/'>{t('home')}</Link>
                <Link href='/fleet-management'>{t('fleet')}</Link>
                <Link href='/technology'>{t('technology')}</Link>
                <Link href='/solution'>{t('solution')}</Link>
                <Link href='/industries'>{t('industries')}</Link>
                <Link href='/blog'>{t('resources')}</Link>
                <Link href='/career'>{t('career')}</Link>
              </div>
              <div className='footer-column'>
                <div className='links' style={{ textAlign: 'center' }}>
                  <Link href='https://www.youtube.com/channel/UCP1b9YkABh37xn_NOrf--3Q/videos' target="_blank"><YoutubeIcon /></Link>
                  <Link href='https://facebook/' target="_blank"><FacebookIcon /></Link>
                  <Link href='https://www.instagram.com/easygogpsofficial/?hl=en' target="_blank"><InstagramIcon /></Link>
                  <Link href='https://id.linkedin.com/company/pt-easygo-indonesia' target="_blank"><LinkedInIcon /></Link>
                </div>
              </div>
            </Col>
            <Col md={8} sm={24}>
              <div style={{ textAlign: 'right' }}>
                <Link href='https://drive.google.com/file/d/1PUQ5Ymxx_ZbXFzYqkMzadOqRirJs0hKw/view?usp=drivesdk' target="_blank"><img width={"60%"} src={Barcode} alt="Barcode" /></Link>
              </div>
            </Col>
          </Row>
        </TweenOne>
      </OverPack>
    </div>
  )
}

export default PreFooter