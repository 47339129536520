import { useLayoutEffect, useState } from "react"

export const findLastIndex = (array : any, searchKey : string, searchValue : string) => {
  var index = array.slice().reverse().findIndex((x : any) => x[searchKey] === searchValue)
  var count = array.length - 1
  var finalIndex = index >= 0 ? count - index : index

  return finalIndex
}

export const isEven = (n : number) => {
  return n % 2 === 0
}

export const formatterNumber = (val: string | number | undefined) => {
  if (!val) return "0";
  return `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".").replace(/\.(?=\d{0,2}$)/g, ",");
}

export const parserNumber = (val: string | undefined) => {
  if (!val) return 0;
  return Number.parseFloat(val.replace(/\$\s?|(\.*)/g, "").replace(/(,{1})/g, ".")).toFixed(2)
}

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}