import TweenOne from 'rc-tween-one'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { IStaticComponent } from "./types"
import { ProductTechDataSource } from './data.source'
import { useEffect, useState } from 'react'
import { Col, Row } from 'antd'

import Product1PNG from '../../assets/images/tech/new-1.png'
import Product2PNG from '../../assets/images/tech/new-2.png'
import Product3PNG from '../../assets/images/tech/new-3.png'
import Product4PNG from '../../assets/images/tech/new-4.png'
import ProductImage from './ProductImage'

const ProductTech: React.FC<IStaticComponent> = ({isMobile, ...rest }) => {
  const wrapper = { className: 'home-page-wrapper product-tech-wrapper' }
  const overPack = { className: 'home-page product-tech', playScale: 0.05 }
  
  const [data, setData] = useState<any>()

  useEffect(() => {
    const initiate = async () => {
      const defaultLng = localStorage.getItem('@defaultLng')
      setData(defaultLng ? (defaultLng === 'en' ? ProductTechDataSource.en : ProductTechDataSource.id) : ProductTechDataSource.id)
    }

    initiate()
  }, [])

  return (
    <div {...rest} {...wrapper}>
      {data && (
        <OverPack {...overPack}>
          <div className="title-wrapper">
            <p className="title-description">{data.description}</p>
            <h2 className="title-h1">{data.title}</h2>
            <p>{data.caption}</p>
          </div>
          <TweenOne
            animation={{ x: '+=30', opacity: 0, type: 'from' }}
            key="product-tech"
            className="content-product-tech"
          >
            <Row className="centered" justify="center" align="middle">
              <Col lg={6} md={12} sm={24} xs={24}>
                <ProductImage id={`seal`} title={`E-Seal`} image={Product1PNG} />
              </Col>
              <Col lg={6} md={12} sm={24} xs={24}>
                <ProductImage id={`tms`} title={`EasyGo TMS`} image={Product2PNG} />
              </Col>
              <Col lg={6} md={12} sm={24} xs={24}>
                <ProductImage id={`tracking`} title={`EasyGo Vehicle<br/>Tracking Devices`} image={Product3PNG} />
              </Col>
              <Col lg={6} md={12} sm={24} xs={24}>
                <ProductImage id={`dashboard`} title={`EasyGo<br/>Smart Dashboard`} image={Product4PNG} />
              </Col>
            </Row>
          </TweenOne>
        </OverPack>
      )}
    </div>
  )
}

export default ProductTech